import { TooltipButton } from '@/components/Button';
import { faLock, faLockOpen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGraph } from 'components/map/context/NetworkMapStoreContext';
import { useFixNode } from 'components/map/hooks/useFixNode';
import { useEffect, useState } from 'react';

interface FixButtonProps {
    nodeId: string;
    className?: string;
}

export const FixButton: React.FC<FixButtonProps> = ({ nodeId, className }) => {
    const fix = useFixNode();
    const graph = useGraph();

    const [internalFixed, setInternalFixed] = useState(false);

    const fixed = graph.getNodeAttribute(nodeId, 'fixed');

    useEffect(() => {
        setInternalFixed(fixed);
    }, [fixed]);

    return (
        <TooltipButton
            variant='tertiary'
            onClick={(e) => {
                e.stopPropagation();
                fix(nodeId, !internalFixed);
                setInternalFixed(!internalFixed);
            }}
            title={internalFixed ? 'Release position' : 'Fix position'}
            className={className}
        >
            <FontAwesomeIcon icon={internalFixed ? faLock : faLockOpen} fixedWidth />
        </TooltipButton>
    );
};
