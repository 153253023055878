import { TooltipButton } from '@/components/Button';
import { faCodeBranch, faCodeCompare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGraph } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { useExpandNode } from 'components/map/hooks/useExpandNode';

interface ExpandButtonProps {
    node: GraphologyNodeAttributes;
    className?: string;
}

export const ExpandButton: React.FC<ExpandButtonProps> = ({ className, node }) => {
    const expandNode = useExpandNode();

    const graph = useGraph();

    const hiddenConnections = graph.filterNeighbors(
        node.id,
        (_, neighbor) => neighbor.hidden && Boolean(neighbor.data || neighbor.groupedData)
    ).length;

    return (
        <TooltipButton
            variant='tertiary'
            onClick={() => expandNode(node, !node.expanded)}
            title={node.expanded ? 'Collapse' : 'Expand'}
            disabled={!node.expanded && !hiddenConnections}
            className={className}
        >
            <FontAwesomeIcon icon={node.expanded ? faCodeCompare : faCodeBranch} fixedWidth />
            {Boolean(hiddenConnections) && <span className='ml-1'>{hiddenConnections}</span>}
        </TooltipButton>
    );
};
