import { cn } from '@/lib/cn';
import { faBrowser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNetworkBackground, useSetNodeHealth } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { useNodeHealth } from 'components/map/hooks/useNodeHealth';
import { TruncatedText } from 'components/TruncatedText';
import { mapBackgroundColors, mapFillColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';
import { NodeToolbar } from '../nodeToolbar/NodeToolbar';
import { strokeHealthStateStyles } from './common/health';

export const DASHBOARD_NODE = 'dashboardNode';
export const DASHBOARD_NODE_SIZE = 35;

export const DashboardNode = memo(({ id, data }: NodeProps<GraphologyNodeAttributes>) => {
    const background = useNetworkBackground();
    const { isHovered, ...hoverProps } = useHover();
    const setHealth = useSetNodeHealth();

    const healthState = useNodeHealth([data.data!]);

    setHealth(id, healthState);

    const showHalo = true;

    if (!data.data) {
        throw new Error('No data provided for DashboardNode');
    }

    return (
        <div
            className={cn('relative p-2 rounded-full', mapBackgroundColors[background])}
            style={{ width: DASHBOARD_NODE_SIZE, height: DASHBOARD_NODE_SIZE }}
            {...hoverProps}
        >
            <NodeToolbar url={`/dashboard/${data.data.sourceId![0]}`} id={id} isVisible={isHovered} data={data} />

            {showHalo && (
                <svg viewBox='0 0 100 100' className={cn('absolute inset-0 w-full')}>
                    <circle
                        cx='50%'
                        cy='50%'
                        r='42'
                        vectorEffect='non-scaling-stroke'
                        strokeWidth={4}
                        className={cn(
                            mapFillColors[background],
                            data.pinned && 'stroke-statusUnknownPrimary',
                            healthState && strokeHealthStateStyles[healthState]
                        )}
                        {...(!data.pinned && healthState && { strokeDasharray: '1.65 2' })}
                    />
                </svg>
            )}
            <FontAwesomeIcon icon={faBrowser} className='absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2' />

            <Handle
                type='target'
                position={Position.Top}
                className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
            />
            <Handle
                type='source'
                position={Position.Bottom}
                className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
            />
            <span className='sr-only'>{data.data.name}</span>

            {data.data.name && (
                <div
                    className={cn(
                        'text-primary text-[8px] rounded-sm leading-tight px-1 py-0.5 font-bold absolute top-[calc(100%+2px)] max-w-[120px] left-1/2 -translate-x-1/2 text-center',
                        mapBackgroundColors[background]
                    )}
                >
                    <TruncatedText title={data.data.name}>{data.data.name}</TruncatedText>
                </div>
            )}
        </div>
    );
});
