import { useCallback } from 'react';
import { useGraph } from '../context/NetworkMapStoreContext';

export function useFixNode() {
    const graph = useGraph();

    return useCallback(
        (nodeId: string, fixed: boolean) => {
            graph.updateNodeAttribute(nodeId, 'fixed', () => fixed);
        },
        [graph]
    );
}
