import { ChangeEvent, KeyboardEvent, useState } from 'react';

const alphaNumericRegex = /[a-zA-Z0-9]/gu;

interface DatasetTabInputProps {
    value?: string;
    setParentValue?: (value: string) => void;
    onBlur?: (value: string) => void;
}

export const DatasetTabInput: React.FC<DatasetTabInputProps> = ({ value, setParentValue, onBlur }) => {
    const [inputValue, setInputValue] = useState(value);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (!e.key.match(alphaNumericRegex)) {
            e.preventDefault();
        }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        setParentValue?.(newValue);
    };

    return (
        <input
            autoFocus
            value={inputValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onClick={(e) => e.stopPropagation()}
            onFocus={(e) => {
                e.stopPropagation();
                setParentValue?.(inputValue ?? '');
            }}
            onBlur={(e) => onBlur?.(e.target.value)}
            className='px-4 border-none outline-none focus:ring-0'
            placeholder='Rename dataset'
            maxLength={25}
            role='input'
        />
    );
};
