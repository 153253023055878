import { useFlag } from 'lib/useFlag';

/**
 * Hook to determine if the new editor layout should be used. This relies on TileEditorContext so cannot be
 * used at the top level of an editor (before the context is defined).
 */
export const useIsNewEditorLayout = () => {
    const flagEnabled = useFlag('newDataStreamEditor');
    return flagEnabled;
};
