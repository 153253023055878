import { FLOATING_EDGE, FloatingEdge } from 'components/map/components/edges/FloatingEdge';
import { DASHBOARD_NODE, DashboardNode } from 'components/map/components/nodes/DashboardNode';
import { GROUP_NODE, GroupNode } from 'components/map/components/nodes/GroupNode';
import { KPI_NODE, KPINode } from 'components/map/components/nodes/KPINode';
import { MONITOR_NODE, MonitorNode } from 'components/map/components/nodes/MonitorNode';
import { OBJECT_NODE, ObjectNode } from 'components/map/components/nodes/ObjectNode';
import { ORGANISATION_NODE, OrganisationNode } from 'components/map/components/nodes/OrganisationNode';
import { WORKSPACE_NODE, WorkspaceNode } from 'components/map/components/nodes/WorkspaceNode';

export enum LayoutTypes {
    hierarchyVertical,
    hierarchyHorizontal,
    network
}

export const nodeTypes = {
    [WORKSPACE_NODE]: WorkspaceNode,
    [DASHBOARD_NODE]: DashboardNode,
    [MONITOR_NODE]: MonitorNode,
    [OBJECT_NODE]: ObjectNode,
    [GROUP_NODE]: GroupNode,
    [KPI_NODE]: KPINode,
    [ORGANISATION_NODE]: OrganisationNode
};

export const edgeTypes = { [FLOATING_EDGE]: FloatingEdge };
