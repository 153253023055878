import { stateValues } from '@squaredup/monitoring';
import { DirectedGraph } from 'graphology';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes } from '../types';

/* Replaces any instances of 'source nodes' with canonical nodes, and rewrites any edges that
 * point to/from those the source nodes to point to/from the canonical nodes.
 *
 * Source nodes refer to the nodes imported from a given source, e.g. SCOM Host, Azure VM, New Relic host.
 * e.g.
 * OPP-APP01 (New Relic) ---is---> OPP-APP01 (canonical)
 * OPP-APP01 (New Relic) ----hosts---> Order Processing Platform
 * ends up as
 * OPP-APP01 (canonical) ---hosts---> Order Processing Platform
 */
export const rewriteSourceToCanonical = (graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>) => {
    // Find all canonicals nodes
    const canonicalNodeIdStateMap = new Map<string, number>();
    graph.forEachNode((node, attr) => {
        if (attr.data?.__canonicalType) {
            canonicalNodeIdStateMap.set(node, stateValues.unknown);
        }
    });

    // Create a map of IDs for any 'is' edges (Source ID -> Canonical ID), any edges that reference these need rewriting
    const toRewrite = new Map<string, string>();
    graph.forEachEdge((_, attr) => {
        if (attr.label === 'is' && canonicalNodeIdStateMap.has(attr.sourceId)) {
            toRewrite.set(attr.targetId, attr.sourceId);
        }
    });

    // Rewrite any edges that start/end with a 'source ID', and make it start/end to the canonical
    graph.updateEachEdgeAttributes((_, attr) => {
        const newOutV = toRewrite.get(attr.sourceId);
        if (newOutV) {
            attr.sourceId = newOutV;
        }
        const newInV = toRewrite.get(attr.targetId);
        if (newInV) {
            attr.targetId = newInV;
        }
        return attr;
    });

    graph.updateEachNodeAttributes((nodeId, attr) => {
        const canonicalIdOfSourceNode = toRewrite.get(nodeId);
        if (canonicalIdOfSourceNode) {
            // Get state of connected source node and apply to canonical if a worse state but do
            // *NOT* call acc.push for this source node as it's been re-written as the canonical
            const stateValue = (stateValues as never)[`${attr.data?.state}`];
            if (stateValue > canonicalNodeIdStateMap.get(canonicalIdOfSourceNode)!) {
                canonicalNodeIdStateMap.set(canonicalIdOfSourceNode, stateValue);
            }
        } else if (attr.data?.__canonicalType) {
            attr.data.sourceNodeIds = Object.entries(toRewrite).reduce((acc, [sourceId, canonicalId]) => {
                if (canonicalId === nodeId) {
                    acc.push(sourceId);
                }
                return acc;
            }, [] as string[]);
        }

        return attr;
    });
};
