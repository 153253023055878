import { DirectedGraph } from 'graphology';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes } from '../types';

// Checks if an edge exists directly in the graph or in any group node
export function edgeExists(graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>, edgeId: string) {
    // check if edge exists in graph
    if (graph.hasEdge(edgeId)) {
        return true;
    }

    // check if edge exists in any group
    return graph.someNode((_, node) => {
        if (!node.groupedData) {
            return false;
        }

        return node.groupedData.edges.some((e) => e.id === edgeId);
    });
}

// Checks if a node exists directly in the graph or in any group node
export function nodeExists(graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>, nodeId: string) {
    // check if node exists in graph
    if (graph.hasNode(nodeId)) {
        return true;
    }

    // check if node exists in any group
    return graph.someNode((_, node) => {
        if (!node.groupedData) {
            return false;
        }

        return node.groupedData.nodes.some((n) => n.id === nodeId);
    });
}
