import { DATASTREAM_RETRY_AFTER_COUNT } from '@squaredup/constants';
import { FormattedStreamData } from '@squaredup/data-streams';
import { AxiosError } from 'axios';
import { QueryClient, QueryKey, UseQueryResult } from 'react-query';

export const DATA_STREAM_RETRY_KEY = 'RetryCount';

export type RetryEntry = {
    retryCount: number;
    retriedAt: number;
};

/**
 * Get the number of times the data stream has been retried.
 *
 * @param queryClient The query client instance.
 * @param queryKey The query key for the data stream.
 * @returns The retry count or undefined if the query key is not provided.
 */
export const getDataStreamRetryCount = (queryClient: QueryClient, queryKey?: QueryKey): number | undefined => {
    if (!queryKey) {
        return undefined;
    }

    return queryClient.getQueryData<RetryEntry>([...queryKey, DATA_STREAM_RETRY_KEY])?.retryCount;
};

/**
 * Get the number of seconds to wait before retrying the data stream.
 * If the data stream has been retried too many times, return undefined.
 *
 * @param result The query result.
 * @param retryCount The number of times the data stream has been retried.
 * @returns The number of seconds to wait before retrying the data stream.
 */
export const getDataStreamRetryAfter = (
    result: UseQueryResult<unknown, unknown>,
    retryCount?: number
): number | undefined => {
    if (retryCount && retryCount > DATASTREAM_RETRY_AFTER_COUNT) {
        return undefined;
    }

    if (result.error && result.error instanceof AxiosError && result.error.response?.data?.retryAfter) {
        return result.error.response.data.retryAfter;
    }

    return (result.data as FormattedStreamData)?.metadata?.retryAfter;
};
