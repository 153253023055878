import { useCallback } from 'react';
import { useGraph } from '../context/NetworkMapStoreContext';
import { syncVisibility } from '../data/utils/syncVisibility';

export function usePinNode() {
    const graph = useGraph();

    return useCallback(
        (nodeId: string, pinned: boolean) => {
            // We can't unpin the last pinned node
            if (!pinned && !graph.someNode((id, node) => node.pinned && id !== nodeId)) {
                return;
            }

            graph.updateNodeAttribute(nodeId, 'pinned', () => pinned);

            syncVisibility(graph);
        },
        [graph]
    );
}
