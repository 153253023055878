import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import stringify from 'fast-json-stable-stringify';
import { range } from 'lodash';
import { useMemo, useState } from 'react';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';
import { Tabs } from 'ui/Tabs';
import { TileWarnings } from 'ui/tile/TileWarnings';
import { useTileEditorContext } from '../contexts/TileEditorContext';
import { useTileEditorStepsContext } from './contexts/TileEditorStepsContext';
import { NewDataTable } from './newLayout/NewDataTable';
import { DataTable } from './preview/DataTable';
import { UnshapedDataOption } from './preview/UnshapedDataOption';
import { UnshapedDataTable } from './preview/UnshapedDataTable';
import { VisualizationPreview } from './preview/VisualizationPreview';
import { useDataStreamFromTileConfig } from 'dashboard-engine/hooks/useDataStreamFromTileConfig';

const defaultProps = {
    notConfiguredError: <NoDataPlaceholder message='No data' />
};

const nonDatasetModeTabs = (isShowingUnshapedData: boolean, hasDockedEditorStep?: boolean) => [
    ...hasDockedEditorStep ? [{
        label: 'Preview',
        component: <VisualizationPreview />
    }] : [],
    {
        label: 'Data',
        component: <div className='h-full p-4'>{isShowingUnshapedData ? 
            <UnshapedDataTable {...defaultProps} /> : 
            <DataTable {...defaultProps} />
        }</div>
    }
];

/**
 * @returns Data panel
 */
export const DataStreamEditorSecondaryViewingPanel = () => {
    const { isDatasetMode, tileConfig } = useTileEditorContext();
    const { editorSteps, isDocked } = useTileEditorStepsContext();
    const { datasets, activeDataTab, setActiveDataTab } = useDatasetContext();

    const [isShowingUnshapedData, setIsShowingUnshapedData] = useState(false);

    const dataTabs = useMemo(() => {
        if (!isDatasetMode) {
            return nonDatasetModeTabs(isShowingUnshapedData, isDocked);
        }

        return [
            ...isDocked ? [{
                label: 'Preview',
                component: <VisualizationPreview />
            }] : [],
            ...datasets.map(({ tableName, config }) => ({
                label: tableName,
                component: isShowingUnshapedData ? (
                    <UnshapedDataTable
                        key={tableName}
                        className='p-4'
                        config={config as DataStreamBaseTileConfig}
                        rawHeaderLabels={true}
                        {...defaultProps}
                    />
                ) : (
                    <DataTable
                        key={tableName}
                        className='p-4'
                        config={config as DataStreamBaseTileConfig}
                        rawHeaderLabels={true}
                        {...defaultProps}
                    />
                )
            })),
            {
                label: 'Output',
                component: <NewDataTable key='output' className='p-4' />
            }
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDocked, editorSteps, stringify(datasets), isDatasetMode, isShowingUnshapedData]);

    const isDataTab =
        (isDatasetMode && range(Number(isDocked), datasets.length + Number(isDocked)).includes(activeDataTab)) ||
        (!isDatasetMode && (isDocked ? activeDataTab !== 0 : activeDataTab === 0));

    const datasetConfig = datasets[activeDataTab - Number(isDocked)]?.config as DataStreamBaseTileConfig;
    const { isConfigured } = useDataStreamFromTileConfig(datasetConfig ?? tileConfig);

    return (
        <div className='relative z-0 h-full min-w-0 min-h-0 border bg-tileBackground border-dividerTertiary'>
            <Tabs
                tabs={dataTabs}
                tabsID='datastreamDataTabs'
                tabListClassName='w-auto'
                selectedIndex={activeDataTab}
                onSelect={(index) => setActiveDataTab(index)}
                additionalControl={
                    <div className='flex px-4 my-auto ml-auto space-x-3'>
                        {isDataTab && isConfigured && (
                            <UnshapedDataOption
                                isShowingUnshapedData={isShowingUnshapedData}
                                setIsShowingUnshapedData={setIsShowingUnshapedData}
                            />
                        )}
                        {/* Show errors for all data sets if no specific data set is selected */}
                        <TileWarnings config={datasetConfig ?? tileConfig} />
                    </div>
                }
            />
        </div>
    );
};
