import { VisualisationHint } from '@squaredup/data-streams';
import VisualisationSkeleton from 'components/VisualisationSkeleton';
import { motion, useAnimation } from 'framer-motion';
import { useCallback, useEffect } from 'react';

export const DataStreamRetrySkeleton = ({
    retryAfter,
    completedAt,
    visType
}: {
    retryAfter: number;
    completedAt?: number;
    visType?: VisualisationHint;
}) => {
    const barControls = useAnimation();
    const animTime = retryAfter + 10;

    const startAnimation = useCallback(() => {
        const elapsedMs = new Date().getTime() - (completedAt ? completedAt : new Date().getTime());
        const timeLeft = retryAfter - Math.floor(elapsedMs / 1000);
        // Continue loading bar animation from where it was left off
        const startScaleX = Math.min(Math.max(0, 1 - timeLeft / retryAfter), 0.9);

        const loadingBarAnim = {
            scaleX: [startScaleX, 0.9, 1],
            transition: {
                duration: animTime,
                times: [0, timeLeft / animTime, 1],
                ease: 'linear'
            }
        };

        barControls.start(loadingBarAnim);
    }, [retryAfter, completedAt, animTime, barControls]);

    useEffect(() => {
        startAnimation();

        window.addEventListener('visibilitychange', startAnimation, false);
        window.addEventListener('focus', startAnimation, false);

        return () => {
            window.removeEventListener('visibilitychange', startAnimation, false);
            window.removeEventListener('focus', startAnimation, false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startAnimation]);

    return (
        <div className='grid size-full place-items-center'>
            <div className='col-start-1 row-start-1'>
                <div className='text-textPrimary'>Data source is busy. Please wait.</div>
                <div className='w-full h-[4px] overflow-hidden rounded-[4px] bg-dividerSecondary mt-[8px] relative'>
                    <motion.div
                        animate={barControls}
                        className='origin-left rounded-[4px] bg-textPrimary size-full'
                        initial={{ scaleX: 0 }}
                    ></motion.div>
                </div>
            </div>
            <div className='col-start-1 row-start-1 size-full loading-spinner'>
                <VisualisationSkeleton
                    // if visualisation is dynamically chosen, use fallback as can change as data loads
                    type={visType}
                    className='size-full loading-spinner'
                />
            </div>
        </div>
    );
};
