import { useCallback } from 'react';
import { useGraph } from '../context/NetworkMapStoreContext';
import { syncVisibility } from '../data/utils/syncVisibility';

export function useCollapseNodes() {
    const graph = useGraph();

    return useCallback(() => {
        graph.updateEachNodeAttributes((_, attr) => ({
            ...attr,
            expanded: false
        }));

        syncVisibility(graph);
    }, [graph]);
}
