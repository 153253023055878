import { TooltipButton } from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import { faCodeFork, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node } from '@squaredup/graph';
import { useMapId } from 'components/map/context/NetworkMapStoreContext';
import { groupSizeThreshold } from 'components/map/data/utils/rewriteLargeNodeGroups';
import { useUngroupNodes } from 'components/map/hooks/useUngroupNodes';
import { TruncatedText } from 'components/TruncatedText';

const ungroupAllLimit = 10;

interface GroupNodeObjectSelectorProps {
    groupId: string;
    groupNodes: Node[];
    className?: string;
}

export const GroupNodeObjectSelector: React.FC<GroupNodeObjectSelectorProps> = ({ groupId, groupNodes, className }) => {
    const mapId = useMapId();
    const ungroupNodes = useUngroupNodes();

    if (!groupNodes?.length) {
        return null;
    }

    return (
        <DropdownMenu>
            <DropdownMenu.Trigger>
                <TooltipButton
                    placement='top'
                    variant='tertiary'
                    onClick={(e) => e.stopPropagation()}
                    title={'Ungroup object'}
                    className={className}
                >
                    <FontAwesomeIcon icon={faCodeFork} fixedWidth />
                </TooltipButton>
            </DropdownMenu.Trigger>

            <DropdownMenu.Menu
                className='w-full p-xxxs min-w-[100px] max-w-[260px] bg-tileBackground overflow-auto max-h-72 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
                align='start'
                container={document.getElementById(mapId) ?? document.body}
            >
                {groupNodes.length <= ungroupAllLimit && (
                    <>
                        <DropdownMenu.Item
                            key='ungroupAll'
                            onClick={(e) => e.stopPropagation()}
                            onSelect={() =>
                                ungroupNodes(
                                    groupId,
                                    groupNodes.map((node) => node.id)
                                )
                            }
                            className='w-full px-2 text-sm font-bold p-xxxs'
                        >
                            <TruncatedText title='Ungroup all objects' className='w-full' tooltipClassName='!z-[100]' />
                        </DropdownMenu.Item>

                        <DropdownMenu.Separator className='my-xxxs' />
                    </>
                )}

                {groupNodes.length !== groupSizeThreshold ? (
                    groupNodes.map((node) => {
                        return (
                            <DropdownMenu.Item
                                key={node.id}
                                onClick={(e) => e.stopPropagation()}
                                onSelect={() => ungroupNodes(groupId, [node.id])}
                                className='w-full px-2 text-sm p-xxxs'
                            >
                                <TruncatedText title={node.name} className='w-full' tooltipClassName='!z-[100]' />
                            </DropdownMenu.Item>
                        );
                    })
                ) : (
                    <DropdownMenu.Item
                        key={'ungroupMessage'}
                        icon={<FontAwesomeIcon icon={faQuestionCircle} />}
                        disabled
                        className='w-full px-2 text-sm p-xxxs text-textDisabled'
                    >
                        Groups consist of {groupSizeThreshold} or more objects, ungrouping a single object will result
                        in all objects being ungrouped.
                    </DropdownMenu.Item>
                )}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};
