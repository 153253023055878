import { Node } from '@squaredup/graph';
import { HealthState, stateStrings, stateValues } from '@squaredup/monitoring';
import { oneMinute } from 'queries/constants';
import { getDashboardHealthWithTileStates } from 'queries/hooks/useDashboardHealthStateRollup';
import { useWorkspaceHealthStates } from 'queries/hooks/useWorkspaceHealthStates';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { useMemo } from 'react';
import { QueryClient, useQuery } from 'react-query';
import { ListDashboardHealthByIds } from 'services/HealthService';

async function getDashboardHealthState(queryClient: QueryClient, dashId: string) {
    const key = dashboardQueryKeys.stateRollup(dashId);

    if (queryClient.getQueryData(key)) {
        return Promise.resolve(queryClient.getQueryData(key)) as ReturnType<typeof getDashboardHealthWithTileStates>;
    }

    return getDashboardHealthWithTileStates(dashId).then((data) => {
        queryClient.setQueryData(key, data);
        return data;
    });
}

export const useDashboardNodesHealth = (dashIds: string[]) => {
    return useQuery(dashboardQueryKeys.stateRollups(dashIds), () => ListDashboardHealthByIds(dashIds), {
        staleTime: oneMinute,
        cacheTime: oneMinute,
        refetchInterval: oneMinute,
        keepPreviousData: true,
        enabled: Boolean(dashIds.length),
        select: (data) => data.dashboardStates.map((d) => d.state)
    });
};

export const useMonitorNodesHealth = (monitorIds: string[]) => {
    const queryClient = new QueryClient();

    const dashIds = useMemo(() => monitorIds.map((id) => id.split('/')[0]), [monitorIds]);
    const tileIds = useMemo(() => monitorIds.map((id) => id.split('/')[1]), [monitorIds]);

    return useQuery(
        dashboardQueryKeys.stateRollups(dashIds),
        () => Promise.all(dashIds.map((id) => getDashboardHealthState(queryClient, id))),
        {
            staleTime: oneMinute,
            cacheTime: oneMinute,
            refetchInterval: oneMinute,
            keepPreviousData: true,
            enabled: Boolean(monitorIds.length),
            select: (data) => data.map((d, i) => d.tileStates[tileIds[i]]?.state)
        }
    );
};

export function useNodeHealth(nodes: Node[]): HealthState {
    const workspaceHealthStates = useWorkspaceHealthStates();
    const workspaceStates = useMemo(() => {
        return nodes.map((node) => {
            if (node.sourceType![0] === 'squaredup/space' || node.type![0] === 'KPI') {
                return workspaceHealthStates.data?.find((ws) => ws.id === node.sourceId?.[0])?.state;
            }
            return undefined;
        });
    }, [nodes, workspaceHealthStates.data]);

    const { data: dashboardHealthStates = [] } = useDashboardNodesHealth(
        nodes.filter((node) => node.sourceType?.[0] === 'squaredup/dash').map((node) => node.sourceId![0])
    );

    const { data: monitorHealthStates = [] } = useMonitorNodesHealth(
        nodes.filter((node) => node.sourceType?.[0] === 'squaredup/monitor').map((node) => node.sourceId![0])
    );

    // return the worst state
    return [...workspaceStates, ...dashboardHealthStates, ...monitorHealthStates].reduce((acc, state) => {
        if (state && stateValues[stateStrings[state ?? stateStrings.unmonitored]] > stateValues[stateStrings[acc!]]) {
            return state!;
        }
        return acc;
    }, stateStrings.unmonitored as HealthState) as HealthState;
}
