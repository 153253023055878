import { Button, TooltipButton } from '@/components/Button';
import { DialogContent } from '@/components/Dialog';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCircleNotch, faPencil, faRefresh, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadixDialog from '@radix-ui/react-dialog';
import { TileConfig } from '@squaredup/dashboards';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { DashboardId } from '@squaredup/ids';
import { TimeframeEnumValue } from '@squaredup/timeframes';
import { ResizablePanelResizeHandle } from 'components/ResizablePanelResizeHandle';
import { TruncatedText } from 'components/TruncatedText';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { useAppContext } from 'contexts/AppContext';
import { useDashboardContext } from 'contexts/DashboardContext';
import TileContext, { useTileContext } from 'contexts/TileContext';
import DataStreamBaseTile from 'dashboard-engine/basetiles/DataStreamBaseTile';
import { useForceRefetch } from 'dashboard-engine/hooks/useForceRefetch';
import { useFullscreenTileConfig } from 'dashboard-engine/hooks/useFullscreenTileConfig';
import { VisualizationState } from 'dashboard-engine/hooks/useVisualizationState';
import { healthReasonMessage } from 'dashboard-engine/util/monitoring';
import { isOpenAccess } from 'lib/openAccessUtil';
import { SaveToDashboardModal } from 'pages/explore/SaveToDashboardModal';
import { ComponentRef, MutableRefObject, useRef, useState } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useLocation } from 'react-router';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { UnshapedDataOption } from 'ui/editor/dataStream/TileEditor/preview/UnshapedDataOption';
import { UnshapedDataTable } from 'ui/editor/dataStream/TileEditor/preview/UnshapedDataTable';
import { useDashboardId } from 'ui/hooks/useDashboardId';
import { KPIIcon } from 'ui/tile/KPIIcon';
import { TileWarnings } from 'ui/tile/TileWarnings';
import { FullscreenTileMenu } from './FullscreenTileMenu';
import { FullscreenTimeframeChooser } from './FullscreenTimeframeChooser';

const FULLSCREEN_TILE_ID = 'tile-fullscreenTile';

const FullscreenTileToolbar = () => (
    <div id={`${FULLSCREEN_TILE_ID}Toolbar`} className='*:flex hide-for-image-export' />
);

const VISUALISATION_HEIGHT_PERCENTAGE = 70;

interface FullscreenTileProps {
    visualizationState: MutableRefObject<VisualizationState>;
    onClose: () => void;
}

/**
 * Get initial timeframe override value for tile.
 * Checks against SQL analytics tiles as well as standard tiles.
 */
const getInitialTimeframeOverride = (
    config: TileConfig,
    dashboardTimeframe: TimeframeEnumValue,
    datasetsHaveSameTimeframe: boolean
) => {
    const isSql = Boolean(config?.dataStream?.dataSourceConfig?.sql?.length);

    if (!isSql) {
        return config?.timeframe ?? dashboardTimeframe;
    }

    // a single dataset is using dashboard timeframe
    const datasetUsesDashboardTimeframe =
        isSql && config?.dataStream?.dataSourceConfig?.tables?.some((tableConfig) => !tableConfig?.config?.timeframe);

    if (datasetUsesDashboardTimeframe && dashboardTimeframe) {
        return dashboardTimeframe;
    }

    if (datasetsHaveSameTimeframe && config?.dataStream?.dataSourceConfig?.tables?.[0]?.config?.timeframe) {
        return config?.dataStream?.dataSourceConfig?.tables?.[0]?.config?.timeframe;
    }

    return undefined;
};

export const FullscreenTile: React.FC<FullscreenTileProps> = ({ visualizationState, onClose }) => {
    const [isShowingUnshapedData, setIsShowingUnshapedData] = useState(false);
    const [isSavingToDashboard, setIsSavingToDashboard] = useState(false);

    const { pathname } = useLocation();

    const { timeframe: dashboardTimeframe } = useDashboardContext();
    const { currentWorkspaceID } = useAppContext();
    const tileContext = useTileContext();

    const { config, health, onEdit } = tileContext;

    const { data: canWriteToWorkspace } = useWorkspaceCanWrite(currentWorkspaceID || undefined);
    const isEditingEnabled = pathname.startsWith('/dashboard/') && canWriteToWorkspace;
    const dashboardId = useDashboardId();

    const isSql = Boolean(config?.dataStream?.dataSourceConfig?.sql?.length);

    // all SQL analytics datasets used same fixed timeframe.
    const datasetsHaveSameTimeframe = Boolean(
        !isSql ||
            config?.dataStream?.dataSourceConfig?.tables?.every(
                (tableConfig) =>
                    tableConfig?.config?.timeframe ===
                    config?.dataStream?.dataSourceConfig?.tables?.[0]?.config?.timeframe
            )
    );

    const [timeframeOverride, setTimeframeOverride] = useState<TimeframeEnumValue | undefined>(() =>
        getInitialTimeframeOverride(config, dashboardTimeframe, datasetsHaveSameTimeframe)
    );

    const configWithTimeframeOverride = useFullscreenTileConfig(
        config as DataStreamBaseTileConfig,
        timeframeOverride,
        // force timeframe override for all SQL datasets if they're using same timeframe
        datasetsHaveSameTimeframe
    );

    const dialogRef = useRef<ComponentRef<typeof DialogContent>>(null);
    const verticalPanelGroupRef = useRef<ComponentRef<typeof PanelGroup>>(null);

    const handleResetVerticalPanelGroupLayout = () => {
        verticalPanelGroupRef.current?.setLayout([
            { sizePercentage: 100 - VISUALISATION_HEIGHT_PERCENTAGE },
            { sizePercentage: VISUALISATION_HEIGHT_PERCENTAGE }
        ]);
    };

    // Some hooks will not work in an open access dashboard so it's key we track whether we're in openaccess
    const isOA = isOpenAccess();

    const { forceRefetch, lastRefetched, isRefetching } = useForceRefetch(config);

    const statusMessage = healthReasonMessage(health?.state, health?.stateReason);

    const hasChangedTimeframe = config?.timeframe !== timeframeOverride;
    const DataTable = isShowingUnshapedData ? UnshapedDataTable : DataStreamBaseTile;

    const isTableVisualisation = config.visualisation?.type === 'data-stream-table';
    const isGrouped = config.dataStream?.group?.aggregate?.length || -1 > 0;
    const isFiltered = config.dataStream?.filter;
    const isSorted = config.dataStream?.sort;
    const isTableShaped = isGrouped || isFiltered || isSorted;

    const handleEdit = () => {
        onEdit();
        onClose();
    };

    const handleOpenSaveToDashboard = () => setIsSavingToDashboard(true);
    const handleCloseSaveToDashboard = () => setIsSavingToDashboard(false);

    if (!config) {
        return null;
    }

    return (
        <>
            <DialogContent
                ref={dialogRef}
                className='fixed flex w-[92.5%] h-full justify-center gap-y-sm left-[3.75%] top-[24px] max-h-[calc(100%-48px)]'
            >
                <TileContext.Provider
                    value={{
                        ...tileContext,
                        config: configWithTimeframeOverride,
                        health: !hasChangedTimeframe ? health : undefined,
                        tileId: FULLSCREEN_TILE_ID
                    }}
                >
                    <PanelGroup
                        ref={verticalPanelGroupRef}
                        direction='vertical'
                        className='min-h-0 border bg-backgroundSecondary border-modalOutline animate animate-enter text-textPrimary'
                        dataAttributes={{ 'data-testid': 'fullscreenDialog' }}
                    >
                        <Panel
                            minSizePixels={160}
                            defaultSizePercentage={isTableVisualisation ? 100 : VISUALISATION_HEIGHT_PERCENTAGE}
                            className='max-h-full bg-tileBackground'
                        >
                            <div
                                id={FULLSCREEN_TILE_ID}
                                className='relative flex flex-col w-full h-full max-h-full gap-y-sm p-lg bg-tileBackground'
                            >
                                <div className='flex items-start justify-between gap-x-xs shrink-0'>
                                    <div className='min-w-0'>
                                        <div className='flex items-center w-full gap-x-sm'>
                                            {health && !hasChangedTimeframe && (
                                                <StateIndicator
                                                    className='w-4 h-4'
                                                    state={health.state}
                                                    titleOverride={statusMessage}
                                                />
                                            )}

                                            <div className='flex flex-1 overflow-hidden gap-x-xs'>
                                                {config.title && (
                                                    <Text.H2 className='flex w-full min-w-0' tabIndex={0}>
                                                        <TruncatedText
                                                            title={config.title}
                                                            element='span'
                                                            className='inline-flex max-w-full'
                                                        >
                                                            {config.title}
                                                        </TruncatedText>
                                                    </Text.H2>
                                                )}

                                                {'kpi' in config && (
                                                    <div className='flex mr-2'>
                                                        <KPIIcon
                                                            kpiConfig={(config as DataStreamBaseTileConfig)?.kpi}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {config.description && (
                                            <Text.H5 className={cn('text-textSecondary w-full', health && 'pl-[30px]')}>
                                                <TruncatedText
                                                    title={config.description}
                                                    element='span'
                                                    className='inline-flex max-w-full'
                                                >
                                                    {config.description}
                                                </TruncatedText>
                                            </Text.H5>
                                        )}
                                    </div>

                                    <div className='flex items-center justify-end gap-x-sm hide-for-image-export'>
                                        {isTableVisualisation && <FullscreenTileToolbar />}

                                        {config._type === 'tile/data-stream' && (
                                            <TileWarnings config={config as DataStreamBaseTileConfig} />
                                        )}

                                        {!isOA && (
                                            <TooltipButton
                                                onClick={forceRefetch}
                                                variant='secondary'
                                                className='px-4'
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={isRefetching ? faCircleNotch : faRefresh}
                                                        spin={isRefetching}
                                                        fixedWidth
                                                    />
                                                }
                                                disabled={isRefetching}
                                                title={lastRefetched}
                                            />
                                        )}
                                        {!isOA && timeframeOverride && (
                                            <FullscreenTimeframeChooser
                                                timeframe={timeframeOverride}
                                                setTimeframe={setTimeframeOverride}
                                            />
                                        )}

                                        {isEditingEnabled && handleEdit && (
                                            <Button
                                                onClick={handleEdit}
                                                variant='secondary'
                                                icon={<FontAwesomeIcon icon={faPencil} />}
                                            >
                                                Edit
                                            </Button>
                                        )}

                                        <FullscreenTileMenu
                                            isShowingUnshapedData={isShowingUnshapedData}
                                            handleOpenSaveToDashboard={handleOpenSaveToDashboard}
                                        />

                                        <RadixDialog.Close asChild>
                                            <FontAwesomeIcon
                                                icon={faXmark}
                                                className='text-xl cursor-pointer text-tertiaryButton hover:text-tertiaryButtonHover'
                                            />
                                        </RadixDialog.Close>
                                    </div>
                                </div>

                                <div className='relative flex w-full h-full min-h-0' data-testid='fullscreenTile'>
                                    <DataStreamBaseTile
                                        config={configWithTimeframeOverride}
                                        initialVisualizationState={visualizationState.current}
                                    />
                                </div>
                            </div>
                        </Panel>

                        {!isTableVisualisation && (
                            <>
                                <ResizablePanelResizeHandle
                                    direction='vertical'
                                    onResetLayout={handleResetVerticalPanelGroupLayout}
                                />
                                <Panel
                                    minSizePixels={130}
                                    className='flex flex-col w-full h-full min-w-0 gap-y-sm p-lg group/tile bg-tileBackground'
                                >
                                    <div className='flex items-center justify-end gap-x-sm'>
                                        <Text.H2 className='mr-auto'>Data</Text.H2>

                                        <FullscreenTileToolbar />

                                        {isTableShaped && (
                                            <UnshapedDataOption
                                                isShowingUnshapedData={isShowingUnshapedData}
                                                setIsShowingUnshapedData={setIsShowingUnshapedData}
                                            />
                                        )}
                                    </div>

                                    <div className='relative w-full h-full min-h-0 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                                        <DataTable
                                            config={{
                                                ...configWithTimeframeOverride,
                                                visualisation: {
                                                    type: 'data-stream-table',
                                                    config: {
                                                        /**
                                                         * This ensures we don't try to generate a default config
                                                         * and transpose a single row table given we're meant to
                                                         * be showing raw data for this panel
                                                         * */
                                                        'data-stream-table': {}
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </Panel>
                            </>
                        )}
                    </PanelGroup>
                </TileContext.Provider>

                {isSavingToDashboard && (
                    <SaveToDashboardModal
                        close={handleCloseSaveToDashboard}
                        config={configWithTimeframeOverride}
                        currentDashboardId={dashboardId as DashboardId['value']}
                        container={dialogRef.current}
                    />
                )}
            </DialogContent>
        </>
    );
};
