import { Query } from 'services/GraphService';
import { NodesAndEdges } from './types';

const nodeValues = [
    '__configId',
    '__canonicalType',
    'name',
    'sourceId',
    'tileName',
    'dashId',
    'tileId',
    'type',
    'sourceType',
    'state'
];

export const fetchMapData = async (
    objectIds?: string[],
    excludeObjectIds?: string[],
    times = 2
): Promise<NodesAndEdges | undefined> => {
    let gremlinQuery = 'g.V()';
    let bindings: Record<string, any> = {};

    // If we have no currentWorkspaceId and no objectIds we have no way of fetching
    // the correct data so simply return
    if (!objectIds) {
        return;
    }

    if (objectIds) {
        gremlinQuery += '.has("id", within(objectIds))';
        bindings.objectIds = objectIds;
    }

    gremlinQuery += `.aggregate("nodesVisited")
        .repeat(
            optional(
                inE("is").aggregate("edgesVisited").otherV().aggregate("nodesVisited")
                .bothE().aggregate("edgesVisited").otherV().aggregate("nodesVisited")
            )
            .bothE().aggregate("edgesVisited")
            .bothV()
                .simplePath()
                .has("sourceType", without("squaredup/scope", "squaredup/tile"))
                ${excludeObjectIds?.length ? '.has("id", without(excludeObjectIds))' : ''}
                .aggregate("nodesVisited")
            .optional(
                inE("is").aggregate("edgesVisited").otherV().simplePath().aggregate("nodesVisited")
                .bothE().aggregate("edgesVisited").otherV().aggregate("nodesVisited")
            )
        )
        .times(${times})
        .cap("edgesVisited").unfold().dedup().aggregate("edges")
        .cap("nodesVisited").unfold().dedup().valueMap(true,${nodeValues
            .map((value) => `"${value}"`)
            .join()}).aggregate("nodes")
        .cap("edges", "nodes")
    `;

    if (excludeObjectIds?.length) {
        bindings.excludeObjectIds = excludeObjectIds;
    }

    const [graphResults] = (
        await Query(
            {
                gremlinQuery,
                bindings
            },
            'directOrAnyWorkspaceLinks'
        )
    ).gremlinQueryResults;

    return graphResults;
};
