import { DataStreamError } from '@squaredup/data-streams';
import _ from 'lodash';
import React from 'react';
import { TileError } from 'ui/tile/TileError';
import { warningMessages } from 'ui/tile/TileWarnings';

interface DataStreamErrorsProps {
    error: any;
    showDetailedErrors?: boolean;
    /** Show error detail inline instead of in tooltip */
    showDetailInline?: boolean;
}

/**
 * Transform errors into a standard format
 */
const transformErrors = (error: any): DataStreamError[] => {
    if (!error) {
        return [];
    }

    if (error?.response?.data?.errors) {
        return error.response.data.errors.map((e: string | object) =>
            typeof e === 'string' ? { message: e } : (e as DataStreamError)
        );
    }

    if (error instanceof Error) {
        return [
            {
                message: error.message,
                name: error.name
            }
        ];
    }

    if (Array.isArray(error)) {
        return error.map((e) => ({ message: _.toString(e) }));
    }

    return [{ message: _.toString(error) }];
};

export const DataStreamErrors: React.FC<DataStreamErrorsProps> = ({
    error,
    showDetailedErrors = true,
    showDetailInline
}) => {
    const errors = transformErrors(error);
    const primaryError = errors?.[0];
    const errorMessage = primaryError ? warningMessages.find((w) => primaryError.message.startsWith(w.message)) : null;

    return (
        <TileError
            heading={showDetailedErrors && errorMessage?.heading ? errorMessage?.heading : 'Something went wrong'}
            articleAnchor={errorMessage?.heading?.toLowerCase().replace(/ /gu, '-')}
            supportCode={error?.response?.headers?.['apigw-requestid']}
            detail={errorMessage?.message || primaryError?.message}
            showDetail={showDetailedErrors}
            showDetailInline={showDetailInline}
        />
    );
};
