import { cn } from '@/lib/cn';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Accordion from '@radix-ui/react-accordion';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { DataStreamAnalyticsStepsSelector } from './DataStreamAnalyticsStepsSelector';
import { DatasetTrigger } from './DatasetTrigger';

const SQL_STEP = 'SQL';
const ADD_DATASET = 'addDataset';


const DataStreamDatasetStepController: React.FC = ({ children }) => {
    const { 
        datasets, 
        activeDataset, 
        isEditingSQL,
        addDataset,  
        setActiveDataset,
        setActiveDataTab,
        setIsEditingSQL
    } = useDatasetContext();

    const { isDocked } = useTileEditorStepsContext();

    return (
        <div className='flex flex-col space-y-4'>
            <Accordion.Root 
                value={isEditingSQL ? SQL_STEP : String(activeDataset)}
                type='single'
                onValueChange={(activeAccordion) => {
                    switch (activeAccordion) {
                        case SQL_STEP:
                            setIsEditingSQL?.(true);
                            setActiveDataset(-1);
                            setActiveDataTab(datasets.length + Number(isDocked));
                            return;
                        case ADD_DATASET:
                            setIsEditingSQL?.(false);
                            return addDataset(1 - Number(isDocked));
                        default:
                            setIsEditingSQL?.(false);
                            setActiveDataset(parseInt(activeAccordion));
                            return setActiveDataTab(parseInt(activeAccordion) + Number(isDocked));
                    }
                }}
                className='space-y-4'
            >
                {datasets.map((dataset, index) => {
                    const isActive = index === activeDataset;

                    return (
                        <Accordion.Item     
                            key={`dataset-${index}`}
                            value={String(index)}
                        >
                            <Accordion.Header>
                                <Accordion.Trigger asChild>
                                    <DatasetTrigger
                                        index={index}
                                        datasetName={dataset.tableName}
                                        isActive={isActive}
                                        isDocked={isDocked}
                                    />
                                </Accordion.Trigger>
                            </Accordion.Header>
                            
                            <Accordion.Content className='border-b border-l-2 border-filterActive'>
                                {children}
                            </Accordion.Content>
                        </Accordion.Item>
                    );
                })}

                <Accordion.Item     
                    key={ADD_DATASET}
                    value={ADD_DATASET}
                >
                    <Accordion.Header>
                        <Accordion.Trigger asChild>
                            <button className='w-full flex justify-between items-center font-semibold py-4 px-[23px] bg-dividerTertiary text-textSecondary hover:text-textPrimary focus:text-textPrimary'>
                                <span className='flex-1 font-semibold text-left'>Add data set</span>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </Accordion.Trigger>
                    </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item     
                    key={SQL_STEP}
                    value={SQL_STEP}
                >
                    <Accordion.Header>
                        <Accordion.Trigger asChild>
                            <button className={cn('w-full flex justify-between items-center font-semibold py-4 px-[23px] bg-dividerTertiary text-textSecondary hover:text-textPrimary focus:text-textPrimary', isEditingSQL && 'bg-filterActive text-textPrimary')}>
                                SQL
                            </button>
                        </Accordion.Trigger>
                    </Accordion.Header>

                    <Accordion.Content className='border-b border-l-2 border-filterActive'>
                        <DataStreamAnalyticsStepsSelector />
                    </Accordion.Content>
                </Accordion.Item>
            </Accordion.Root>
        </div>
    );
};

export const DataStreamTileEditorDatasetStepWrapper: React.FC = ({ children }) => {
    const { isDatasetMode } = useTileEditorContext();

    if (!isDatasetMode) {
        return <>{children}</>;
    }

    return (
        <DataStreamDatasetStepController>
            {children}
        </DataStreamDatasetStepController>
    );
};