import { DirectedGraph } from 'graphology';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes } from '../types';

// Ensures that nodes and edges are visible based on their expanded state or proximity to expanded nodes
export function syncVisibility(graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>) {
    // Set the visibility of nodes based on their expanded state or proximity to expanded nodes
    graph.updateEachNodeAttributes((nodeId, attr) => ({
        ...attr,
        hidden: !(attr.expanded || attr.pinned || graph.someNeighbor(nodeId, (_, { expanded }) => expanded))
    }));

    // Set the visibility of edges based on the visibility of their source and target nodes
    graph.updateEachEdgeAttributes((_, attr, sourceId, targetId) => ({
        ...attr,
        hidden: graph.getNodeAttribute(sourceId, 'hidden') || graph.getNodeAttribute(targetId, 'hidden')
    }));
}
