import { DASHBOARD_NODE } from 'components/map/components/nodes/DashboardNode';
import { GROUP_NODE, GROUP_NODE_SIZE } from 'components/map/components/nodes/GroupNode';
import { KPI_NODE } from 'components/map/components/nodes/KPINode';
import { WORKSPACE_NODE } from 'components/map/components/nodes/WorkspaceNode';
import { DirectedGraph } from 'graphology';
import { v4 } from 'uuid';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes } from '../types';
import { addEdge } from './addEdge';
import { getGraphologyEdgeAttributes, getGraphologyNodeAttributes } from './convertToGraphology';
import { syncVisibility } from './syncVisibility';
export const groupSizeThreshold = 3;

export function ungroupedNodes(
    graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>,
    groupId: string,
    // if provided, these nodes will be permanently ungrouped
    nodeIds?: string[]
) {
    const group = graph.getNodeAttributes(groupId);
    if (!group) {
        return;
    } else if (group.type !== 'groupNode') {
        throw new Error('Cannot ungroup a non-group node');
    }

    group.groupedData!.nodes.forEach((nodeData) => {
        if (!nodeIds || nodeIds.includes(nodeData.id)) {
            graph.mergeNode(
                nodeData.id,
                getGraphologyNodeAttributes(nodeData, {
                    expanded: false,
                    pinned: false,
                    ungrouped: nodeIds?.includes(nodeData.id) || false,
                    hidden: !(nodeIds?.includes(nodeData.id) || false)
                })
            );
        }
    });

    group.groupedData!.nodes = group.groupedData!.nodes.filter((nodeData) => nodeIds && !nodeIds.includes(nodeData.id));

    group.groupedData!.edges.forEach((edgeData) => {
        if (!nodeIds || nodeIds?.includes(edgeData.inV) || nodeIds?.includes(edgeData.outV)) {
            graph.mergeEdge(edgeData.inV, edgeData.outV, getGraphologyEdgeAttributes(edgeData));
        }
    });

    group.groupedData!.edges = group.groupedData!.edges.filter(
        (edgeData) => nodeIds && !(nodeIds.includes(edgeData.inV) || nodeIds.includes(edgeData.outV))
    );

    graph.forEachEdge(groupId, (edgeId, { data }) => {
        if (!group.groupedData!.edges.some((edge) => edge.id === data.id)) {
            graph.dropEdge(edgeId);
        }
    });

    if (!group.groupedData?.nodes.length) {
        graph.dropNode(groupId);
    }
}

/**
 * When a node has lots of connected nodes it's best to group them based on the sourceType otherwise clarity is lost
 * We need to recursively call this function as grouped nodes may point to other grouped nodes
 */
export const rewriteLargeNodeGroups = (graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>) => {
    // We want to ungroup any hidden group nodes since we might want to show
    // some nodes in another group if other nodes are later expanded
    graph.forEachNode((nodeId, node) => {
        if (node.type === GROUP_NODE && node.hidden) {
            ungroupedNodes(graph, nodeId);
        }
    });

    // We want to ensure that we have all the correct visibility states before grouping
    syncVisibility(graph);

    const groupedNodes = new Set<string>();
    const groupMap = new Map<string, Set<string>>();

    // Find all nodes that are eligible to be grouped
    graph.forEachNode((nodeId, node) => {
        if (node.hidden || !node.expanded) {
            // We only want to group the neighbors of visible & expanded nodes
            return;
        }

        const eligibleNeighbors = new Map<string, Set<string>>();
        graph.forEachNeighbor(nodeId, (neighborId, { type, ungrouped, hidden, expanded, pinned, data }) => {
            if (
                [WORKSPACE_NODE, DASHBOARD_NODE, GROUP_NODE, KPI_NODE].includes(type) ||
                ungrouped ||
                hidden ||
                expanded ||
                pinned ||
                !type ||
                groupedNodes.has(neighborId)
            ) {
                return;
            }

            const groupType = data?.type?.[0] || data?.sourceType?.[0] || '';

            const groupItems = eligibleNeighbors.get(groupType) || new Set<string>();

            groupItems.add(neighborId);

            eligibleNeighbors.set(groupType, groupItems);
        });

        for (const [groupType, groupItems] of eligibleNeighbors) {
            if (groupItems.size >= groupSizeThreshold) {
                const groupId = `group-${groupType}-${v4()}`;
                groupMap.set(groupId, groupItems);
                groupItems.forEach((neighborId) => groupedNodes.add(neighborId));
            }
        }
    });

    // For each group, create a new node and add it to the graph
    groupMap.forEach((neighbors, groupId) => {
        const groupNode: GraphologyNodeAttributes = {
            id: groupId,
            expanded: false,
            height: GROUP_NODE_SIZE,
            width: GROUP_NODE_SIZE,
            hidden: false,
            pinned: false,
            type: GROUP_NODE,
            ungrouped: false,
            x: 0,
            y: 0,
            fixed: false,
            groupedData: {
                nodes: Array.from(neighbors).map((nodeId) => graph.getNodeAttributes(nodeId).data!),
                edges: Array.from(neighbors).flatMap((nodeId) => graph.mapEdges(nodeId, (_, edge) => edge.data)),
                type: groupId.split('-')[1] + 's'
            }
        };

        graph.mergeNode(groupId, groupNode);

        // Cleanup old nodes & edges
        groupNode.groupedData!.nodes.forEach((node) => {
            // Drop edge and replace with new edge to group node
            const nodeEdges = graph.mapEdges(node.id, (_, edge) => edge);

            graph.dropNode(node.id);

            nodeEdges.forEach((edge) => {
                addEdge(graph, edge.data);
            });
        });
    });

    syncVisibility(graph);
};
