import { faChartNetwork, faSitemap } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LayoutTypes } from '../types';

interface LayoutIconProps {
    layoutType: LayoutTypes;
}

export const LayoutIcon: React.FC<LayoutIconProps> = ({ layoutType }) => {
    switch (layoutType) {
        case LayoutTypes.network:
            return <FontAwesomeIcon icon={faChartNetwork} fixedWidth />;
        case LayoutTypes.hierarchyHorizontal:
            return <FontAwesomeIcon icon={faSitemap} rotation={270} fixedWidth />;
        default:
            return <FontAwesomeIcon icon={faSitemap} fixedWidth />;
    }
};
