import { FLOATING_EDGE } from 'components/map/components/edges/FloatingEdge';
import { DASHBOARD_NODE } from 'components/map/components/nodes/DashboardNode';
import { GROUP_NODE } from 'components/map/components/nodes/GroupNode';
import { KPI_NODE } from 'components/map/components/nodes/KPINode';
import { MONITOR_NODE } from 'components/map/components/nodes/MonitorNode';
import { OBJECT_NODE } from 'components/map/components/nodes/ObjectNode';
import { ORGANISATION_NODE } from 'components/map/components/nodes/OrganisationNode';
import { WORKSPACE_NODE } from 'components/map/components/nodes/WorkspaceNode';
import { Edge, Node } from 'reactflow';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes, GraphologyNodeType } from '../types';

export type ReactFlowNode = Node<GraphologyNodeAttributes>;
export type ReactFlowEdge = Edge<GraphologyEdgeAttributes>;

const ReactFlowTypeMap: Record<GraphologyNodeType, string> = {
    workspaceNode: WORKSPACE_NODE,
    dashboardNode: DASHBOARD_NODE,
    monitorNode: MONITOR_NODE,
    objectNode: OBJECT_NODE,
    groupNode: GROUP_NODE,
    kpiNode: KPI_NODE,
    tenantNode: ORGANISATION_NODE
};

export function getReactFlowNode(node: GraphologyNodeAttributes): ReactFlowNode {
    return {
        data: node,
        id: node.id,
        position: { x: node.x, y: node.y },
        width: node.width,
        height: node.height,
        type: ReactFlowTypeMap[node.type],
        hidden: node.hidden
    };
}

export function getReactFlowEdge(edge: GraphologyEdgeAttributes): ReactFlowEdge {
    return {
        id: edge.id,
        source: edge.sourceId,
        target: edge.targetId,
        data: edge,
        hidden: false,
        type: FLOATING_EDGE
    };
}
