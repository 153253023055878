import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import * as Popover from '@radix-ui/react-popover';
import { EditorSteps } from '../constants';
import { StepWithCondition } from '../hooks/useTileEditorSteps';
import { useDOMElement } from 'components/hooks/useDOMElement';


interface DataStreamTileEditorStepSelectorProps {
    editorSteps: StepWithCondition[];
    isDocked: boolean | undefined;
    currentEditorStep?: EditorSteps;
    setCurrentEditorStep: (step: EditorSteps | undefined) => void;
    onInteractOutside?: Popover.PopoverContentProps['onInteractOutside'];
}


/**
 * @returns Steps such as: Data Stream, Objects, Configure, Shaping (step names subject to change)
 */
export const DataStreamTileEditorStepSelector: React.FC<DataStreamTileEditorStepSelectorProps> = ({ 
    editorSteps, 
    currentEditorStep,
    isDocked,
    setCurrentEditorStep,
    onInteractOutside 
}) => {
    const container = useDOMElement('dialogContent');

    return (
        <>
            {editorSteps
                .filter(({ isEnabled }) => isEnabled)
                .map(({ name, sublabel, component }) => {
                    const isOpen = currentEditorStep === name;

                    return (
                        <Popover.Root 
                            key={name} 
                            open={isOpen}
                        >
                            <Popover.Trigger
                                className={cn(
                                    'font-semibold outline-none w-full text-left py-4 px-[23px] shrink-0 h-[79px] text-textPrimary border-b-[1px] border-dividerTertiary disabled:text-textDisabled',
                                    name === currentEditorStep && 'bg-tagBackground'
                                )}
                                data-testid={`step-${name}`}
                                onClick={() => {
                                    // We don't want to close the open editor step if it's docked
                                    setCurrentEditorStep(isOpen && !isDocked ? undefined : name);
                                }}
                            >
                                <Text.H4 className='text-textSecondary'>{[EditorSteps.metadata, EditorSteps.tileMetadata].includes(name) ? EditorSteps.metadata : name}</Text.H4>
                                <Text.H4 className='font-semibold'>
                                    {sublabel}
                                </Text.H4>
                            </Popover.Trigger>

                            {/* When a step is pinned we don't want to show the popover 
                                as it will be in the main visualisation panel */}
                            {!isDocked && 
                                <Popover.Portal container={container} >
                                    <Popover.Content 
                                        side='right'
                                        align='start'
                                        sideOffset={3}
                                        alignOffset={-1}
                                        collisionPadding={14}
                                        onInteractOutside={onInteractOutside}
                                        className={cn(
                                            'border bg-tileBackground z-10 flex flex-col border-dividerTertiary text-textSecondary shadow-even shadow-tileEditorShadow min-h-0 max-h-[min(60vh,650px)] w-[57.5vw] max-w-[900px)',
                                            currentEditorStep === EditorSteps.timeframe && 'max-w-[600px]',
                                            currentEditorStep === EditorSteps.objects && 'min-h-[min(60vh,800px)]',
                                            currentEditorStep === EditorSteps.query && 'min-h-[min(40vh,500px)]',
                                            currentEditorStep === EditorSteps.shaping && 'min-h-[min(40vh,300px)]'
                                        )}>
                                        {component}
                                        
                                        <Popover.Arrow className='fill-dividerTertiary' />
                                    </Popover.Content>
                                </Popover.Portal>
                            }
                        </Popover.Root>
                    );
                })}
        </>
    );
};
