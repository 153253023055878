import * as TWEEN from '@tweenjs/tween.js';
import stringify from 'fast-json-stable-stringify';
import { useEffect, useRef, useState } from 'react';
import { useNodesInitialized, useReactFlow } from 'reactflow';
import { Layout } from 'webcola';
import { useGraph, useLayoutType } from '../context/NetworkMapStoreContext';
import { LayoutTypes } from '../types';

const animationDuration = 350;
const frameMs = 16.7;
const targetLength = 40;
const layouts = new Map([
    [
        LayoutTypes.hierarchyVertical,
        new Layout()
            .flowLayout('y', targetLength * 2.5)
            .jaccardLinkLengths(targetLength * 3.25, 1)
            .groupCompactness(0.25)
            .avoidOverlaps(true)
            .start()
            .stop()
    ],
    [
        LayoutTypes.hierarchyHorizontal,
        new Layout()
            .flowLayout('x', targetLength * 4)
            .jaccardLinkLengths(targetLength * 3.25, 1)
            .groupCompactness(0.25)
            .avoidOverlaps(true)
            .start()
            .stop()
    ],
    [
        LayoutTypes.network,
        new Layout()
            .linkDistance(targetLength)
            .symmetricDiffLinkLengths(targetLength * 0.175, 1)
            .groupCompactness(2)
            .avoidOverlaps(true)
            .start()
            .stop()
    ]
]);

export const useAnimateLayout = () => {
    const isNodesInitialized = useNodesInitialized();
    const { fitView } = useReactFlow();
    const graph = useGraph();
    const layoutType = useLayoutType();
    const previousLayout = useRef(layoutType);
    const previousStateString = useRef('');
    const [isReactFlowReady, setIsReactFlowReady] = useState(isNodesInitialized);
    const nodeStateString = stringify(
        graph
            .mapNodes((_, attr) => attr)
            .filter(({ hidden, id }) => !hidden && Boolean(id))
            .sort((a, b) => a.id.localeCompare(b.id))
            .map(({ id, expanded, pinned }) => [id, expanded, pinned])
    );

    useEffect(() => {
        // We only need to set isReactFlowReady once to use fitView so we handle it in the useEffect
        // (useNodesInitialized changes every time new nodes are added, but once ReactFlow is initialized
        // you can use the zoom function)
        if (isNodesInitialized) {
            setIsReactFlowReady(true);
        }
    }, [isNodesInitialized]);

    useEffect(() => {
        const layout = layouts.get(layoutType);
        const isDifferentLayoutType = previousLayout.current !== layoutType;
        const isDifferentStateString = previousStateString.current !== graph.toString();

        if (!layout || !isReactFlowReady) {
            return;
        }

        // Track the current layoutType
        if (isDifferentLayoutType) {
            previousLayout.current = layoutType;
        }

        // Track the current stateString
        if (isDifferentStateString) {
            previousStateString.current = graph.toString();
        }

        let animationFrame = 0;

        const nodesForAnimation = graph.filterNodes((_, node) => Boolean(node.type) && !node.hidden);

        const positionedNodes = nodesForAnimation.map((nodeId) => {
            const node = graph.getNodeAttributes(nodeId)!;

            const width = node.width + 140;
            const height = node.height + 140;

            return {
                id: node.id,
                width: Math.max(width, 120),
                height: Math.max(height, 120),
                fixed: node.fixed ? 1 : 0,
                x: isDifferentLayoutType ? width / 2 : node.x,
                y: isDifferentLayoutType ? height / 2 : node.y,
                type: node.type
            };
        });

        const edges = graph
            .mapEdges((_, edge) => edge)
            .filter(
                ({ sourceId, targetId }) =>
                    positionedNodes.some(({ id }) => sourceId === id) &&
                    positionedNodes.some(({ id }) => targetId === id)
            )
            .map((edge) => {
                const source = positionedNodes.find(({ id }) => edge.sourceId === id)!;
                const target = positionedNodes.find(({ id }) => edge.targetId === id)!;

                return {
                    ...edge,
                    source,
                    target
                };
            });

        const tweenNodePositionsMap = new Map(positionedNodes.map(({ id, x, y }) => [id, { x, y }]));

        const animate = () => {
            layout.stop();

            const layoutNodePositionsMap = new Map(
                layout.nodes().map(({ id, x, y, width, height, fixed }: any) => [
                    id,
                    {
                        x: fixed ? x : x - width / 2,
                        y: fixed ? y : y - height / 2
                    }
                ])
            );

            tweenNodePositionsMap.forEach((position, id) => {
                const targetPosition = layoutNodePositionsMap.get(id);

                if (targetPosition) {
                    TWEEN.add(
                        new TWEEN.Tween(position)
                            .to(targetPosition, animationDuration)
                            .easing(TWEEN.Easing.Circular.Out)
                            .start()
                    );
                }
            });

            animateFrame();
        };

        const animateFrame = (time = 0) => {
            TWEEN.update();

            graph.updateEachNodeAttributes((id, node) => {
                const tweenPosition = tweenNodePositionsMap.get(id);

                if (tweenPosition && !node.fixed) {
                    node.x = tweenPosition.x;
                    node.y = tweenPosition.y;
                }

                return node;
            });

            if (time > animationDuration * 1.5) {
                TWEEN.removeAll();
                cancelAnimationFrame(animationFrame);
            } else {
                animationFrame = requestAnimationFrame(() => animateFrame(time + frameMs));
            }

            // TODO - figure out a way we can animate the fitView
            fitView({ padding: 0.2, duration: 0 });
        };

        layout
            .nodes(positionedNodes)
            .links(edges)
            .groups([])
            .avoidOverlaps(true)
            .handleDisconnected(false)
            .start(150, 150, 150, undefined, false);

        animate();

        return () => cancelAnimationFrame(animationFrame);
    }, [layoutType, graph, isReactFlowReady, nodeStateString]); // eslint-disable-line react-hooks/exhaustive-deps
};
