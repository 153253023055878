import { isDefined } from '@squaredup/utilities';
import { MouseEvent, useCallback, useEffect } from 'react';
import { ReactNode } from 'react-markdown';
import { useNavigate } from 'react-router';
import ReactFlow, { Controls, useEdgesState, useNodesState } from 'reactflow';
import { useGraph } from '../context/NetworkMapStoreContext';
import { getReactFlowEdge, getReactFlowNode, ReactFlowNode } from '../data/utils/convertToReactFlow';
import { useAnimateLayout } from '../hooks/useAnimateLayout';
import { useExpandNode } from '../hooks/useExpandNode';
import { edgeTypes, nodeTypes } from '../types';
import { ArrowHeadMarkers } from './edges/ArrowHeadMarkers';
import { LayoutButton } from './LayoutButton';
import { ORGANISATION_NODE } from './nodes/OrganisationNode';
import { CollapseAllButton } from './nodeToolbar/CollapseAllButton';
import { DefaultControls } from './nodeToolbar/DefaultControls';

interface LayoutFlowProps {
    controls?: ReactNode;
    hideCollapseButton?: boolean;
}

const useReactFlowData = () => {
    const graph = useGraph();

    const getGraphNodes = useCallback(() => {
        return graph
            .mapNodes((_, node) => (node.type ? getReactFlowNode(node) : null))
            .filter(isDefined)
            .filter((node) => !node.data.hidden);
    }, [graph]);

    const getGraphEdges = useCallback(() => {
        return graph.mapEdges((_, edge) => getReactFlowEdge(edge));
    }, [graph]);

    const [nodes, setNodes, onNodesChange] = useNodesState(getGraphNodes());

    const [edges, setEdges, onEdgesChange] = useEdgesState(getGraphEdges());

    useEffect(() => {
        // TODO - we can be much smarter here and only update the nodes that have changed
        const nodeListener = () => {
            setNodes(getGraphNodes());
        };

        // TODO - we can be much smarter here and only update the edges that have changed
        const edgeListener = () => {
            setEdges(getGraphEdges());
        };

        graph.on('nodeAdded', nodeListener);
        graph.on('nodeDropped', nodeListener);
        graph.on('nodeAttributesUpdated', nodeListener);
        graph.on('eachNodeAttributesUpdated', nodeListener);

        graph.on('edgeAdded', edgeListener);
        graph.on('edgeAttributesUpdated', edgeListener);
        graph.on('eachEdgeAttributesUpdated', edgeListener);
        graph.on('edgeDropped', edgeListener);

        return () => {
            graph.off('nodeAdded', nodeListener);
            graph.off('nodeDropped', nodeListener);
            graph.off('nodeAttributesUpdated', nodeListener);

            graph.off('edgeAdded', edgeListener);
            graph.off('edgeDropped', edgeListener);
            graph.off('edgeAttributesUpdated', edgeListener);
            graph.off('eachEdgeAttributesUpdated', edgeListener);
        };
    }, [graph, setNodes, setEdges, getGraphNodes, getGraphEdges]);

    return { nodes, edges, onNodesChange, onEdgesChange };
};

const useHandleClick = () => {
    const expandNode = useExpandNode();

    const navigate = useNavigate();

    return useCallback(
        (_: MouseEvent, node: ReactFlowNode) => {
            if (node.type === ORGANISATION_NODE) {
                return;
            }

            if (node.type === 'kpiNode') {
                navigate(`/workspace/${node.data.data?.sourceId![0]}`);
            }

            // If clicking a node, expand/collapse it
            expandNode(node.data, !node.data.expanded);
        },
        [expandNode, navigate]
    );
};

export const LayoutFlow = ({ controls, hideCollapseButton }: LayoutFlowProps) => {
    const graph = useGraph();

    const { nodes, edges, onNodesChange, onEdgesChange } = useReactFlowData();

    useAnimateLayout();

    const handleNodeClick = useHandleClick();

    return (
        <>
            <ArrowHeadMarkers />
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                minZoom={0.1}
                proOptions={{ hideAttribution: true }}
                elevateNodesOnSelect={true}
                nodesConnectable={false}
                selectionOnDrag={false}
                onNodeDrag={(_, node) => {
                    graph.updateNodeAttributes(node.id, (attr) => ({
                        ...attr,
                        x: node.position.x,
                        y: node.position.y,
                        fixed: true
                    }));
                }}
                onNodeClick={handleNodeClick}
            >
                <Controls
                    showZoom={false}
                    showFitView={false}
                    showInteractive={false}
                    className='flex flex-col overflow-hidden border divide-y rounded divide-dividerPrimary border-dividerPrimary bg-tileBackground'
                >
                    {controls}
                    <DefaultControls />
                    <LayoutButton />
                    {!hideCollapseButton && <CollapseAllButton />}
                </Controls>
            </ReactFlow>
        </>
    );
};
