import { Toggle } from '@/components/forms/Toggle';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import { useState } from 'react';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { defaultSQL } from '../hooks/useDatasets';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { useIsNewEditorLayout } from '../newLayout/useIsNewEditorLayout';

const DisableAnalyticsPrompt: React.FC = () => {
    return <p>Only the first dataset will be retained when disabling SQL Analytics</p>;
};

const EnableAnalyticsPrompt: React.FC = () => {
    return (
        <div className='space-y-4'>
            <p>SQL Analytics enables you to:</p>
            <ul className='block pl-8 list-disc'>
                <li>Define multiple datasets</li>
                <li>Use a SQL query to aggregate and shape your data</li>
            </ul>
            <p>
                This feature is in Beta. We welcome your feedback.{' '}
                <a
                    className='text-textLink'
                    href='https://squaredup.com/cloud/analytics/'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Learn more.
                </a>
            </p>
        </div>
    );
};

export const AnalyticsToggle: React.FC = () => {
    const { isDatasetMode, setTileConfig } = useTileEditorContext();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const datasetModeChange = () => {
        setTileConfig((currentConfig) => {
            // If switching to multiple datasets mode, use the tile's config to form the first dataset
            if (!isDatasetMode) {
                return {
                    ...currentConfig,
                    visualisation: undefined,
                    timeframe: undefined,
                    scope: undefined,
                    dataStream: {
                        id: 'datastream-sql',
                        dataSourceConfig: {
                            sql: defaultSQL,
                            tables: [
                                {
                                    tableName: 'dataset1',
                                    config: {
                                        scope: currentConfig.scope,
                                        dataStream: currentConfig.dataStream,
                                        timeframe: currentConfig.timeframe,
                                        variables: currentConfig.variables
                                    }
                                }
                            ]
                        }
                    }
                };
            }

            // If switching from multiple datasets mode, use the first dataset to form the tile config
            return {
                _type: 'tile/data-stream',
                title: currentConfig.title,
                description: currentConfig.description,
                monitor: currentConfig.monitor,
                ...currentConfig.dataStream?.dataSourceConfig?.tables?.[0]?.config
            };
        });
    };

    return (
        <>
            <div className='flex items-center space-x-4'>
                <label htmlFor='analytics-toggle' className='select-none whitespace-nowrap'>
                    Enable SQL Analytics
                </label>
                <Toggle
                    name='analytics-toggle'
                    checked={isDatasetMode}
                    onCheckedChange={() => setConfirmationModalOpen(true)}
                    data-testid='analyticsToggle'
                />
            </div>

            {confirmationModalOpen && (
                <ConfirmationPrompt
                    title={`${isDatasetMode ? 'Disable' : 'Enable'} SQL Analytics (BETA)`}
                    prompt={isDatasetMode ? <DisableAnalyticsPrompt /> : <EnableAnalyticsPrompt />}
                    confirmButtonText='Continue'
                    onClose={() => setConfirmationModalOpen(false)}
                    onConfirm={datasetModeChange}
                />
            )}
        </>
    );
};
