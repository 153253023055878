import { cn } from '@/lib/cn';
import * as RadixDialog from '@radix-ui/react-dialog';
import { useDOMElement } from 'components/hooks/useDOMElement';
import TooltipContainerContext from 'contexts/TooltipContainerContext';
import { ReactNode, forwardRef } from 'react';


export const Dialog: React.FC<React.ComponentPropsWithoutRef<typeof RadixDialog.Root>> = ({ children, ...props }) => (
    <RadixDialog.Root {...props}>
        {children}
    </RadixDialog.Root>
);

export const DialogTrigger: React.FC = ({ children }) => (
    <RadixDialog.Trigger asChild>
        {children}
    </RadixDialog.Trigger>
);

interface DialogContentProps {
    className?: string;
    children?: ReactNode;
}

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(({ className, children }, ref) => {
    const dialogElement = useDOMElement('dialogContent');

    return (
        <RadixDialog.Portal>
            <RadixDialog.Overlay className='fixed inset-0 bg-modalBackground backdrop-filter backdrop-blur-md' />
            <RadixDialog.Content 
                className={cn(className)} 
                id='dialogContent'
                ref={ref}
            >
                <TooltipContainerContext.Provider value={dialogElement}>
                    {children}
                </TooltipContainerContext.Provider>
            </RadixDialog.Content>
        </RadixDialog.Portal>
    );
});

