import { DirectedGraph } from 'graphology';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes, NodesAndEdges } from '../types';
import { addEdge } from './addEdge';
import { getGraphologyNodeAttributes } from './convertToGraphology';
import { nodeExists } from './nodeExists';
import { rewriteLargeNodeGroups } from './rewriteLargeNodeGroups';
import { rewriteSourceToCanonical } from './rewriteSourceToCanonical';

export function insertQueryResults(
    graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>,
    queryResults?: NodesAndEdges,
    expandedIds?: string[]
) {
    if (!queryResults) {
        return;
    }

    const { nodes, edges } = queryResults;

    nodes.forEach((node) => {
        if (!nodeExists(graph, node.id)) {
            const expanded = expandedIds?.includes(node.id) ?? false;
            graph.addNode(
                node.id,
                getGraphologyNodeAttributes(node, {
                    expanded,
                    pinned: expanded,
                    ungrouped: false
                })
            );
        }
    });

    edges.forEach((edge) => addEdge(graph, edge));

    rewriteSourceToCanonical(graph);
    rewriteLargeNodeGroups(graph);
}
