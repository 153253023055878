import { useCallback, useEffect, useRef } from 'react';
import { useCheckScopeIsOobAndHasLimit } from 'ui/tile/hooks/useCheckScopeIsOobAndHasLimit';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { EditorSteps } from '../constants';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { DataStreamTileEditorDatasetStepWrapper } from './DataStreamTileEditorDatasetStepWrapper';
import { DataStreamTileEditorStepSelector } from './DataStreamTileEditorStepSelector';

export const radixPointerEventType = 'dismissableLayer.pointerDownOutside';

export const DataStreamTileEditorSteps: React.FC = () => {
    const { config } = useDatasetContext();
    const automaticallyNavigated = useRef(false);

    const { isLoading: isLoadingLimit, limit } = useCheckScopeIsOobAndHasLimit(config);
    const { 
        currentEditorStep, 
        editorSteps, 
        isDocked,
        setCurrentEditorStep
    } = useTileEditorStepsContext();

    useEffect(() => {
        if (!isLoadingLimit && limit !== false && !automaticallyNavigated.current) {
            setCurrentEditorStep(EditorSteps.objects);
            automaticallyNavigated.current = true;
        }
    }, [isLoadingLimit, limit, setCurrentEditorStep]);

    const stepComponentRef = useRef<HTMLDivElement>(null);

    // Set up esc closing step popover
    const handleClose = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setCurrentEditorStep(undefined);
        }
    }, [setCurrentEditorStep]);

    useEffect(() => {
        window.addEventListener('keyup', handleClose);
        return () => window.removeEventListener('keyup', handleClose);
    });

    return (
        <div className='h-full min-h-0 shrink-0 bg-tileBackground'>
            <div className='flex h-full'>
                <div 
                    className='w-64 overflow-auto text-base border border-dividerTertiary tile-scroll-overflow scrollbar-thumb-statusUnknownPrimary'
                    id='dataStreamEditorSteps'
                >
                    <DataStreamTileEditorDatasetStepWrapper>
                        <div 
                            className='flex flex-col'
                            ref={stepComponentRef}
                        >
                            <DataStreamTileEditorStepSelector 
                                editorSteps={editorSteps}
                                currentEditorStep={currentEditorStep}
                                isDocked={isDocked}
                                setCurrentEditorStep={setCurrentEditorStep}
                                onInteractOutside={(e) => {
                                    if (
                                        e.type === radixPointerEventType && 
                                        !stepComponentRef.current?.contains(e.target as any)
                                    ) {
                                        setCurrentEditorStep(undefined);
                                    }
                                }}
                            />
                        </div>
                    </DataStreamTileEditorDatasetStepWrapper>
                </div>
            </div>
        </div>
    );
};
