import { Node } from '@squaredup/graph';
import { createContext, useContext } from 'react';
import { InfiniteQueryObserverResult } from 'react-query';
import { useDataStreamObjectFilters } from '../TileEditor/hooks/useDataStreamObjectFilters';
import { emptyFilters } from '../TileEditor/state/scope/ObjectFilterState';

type TileEditorObjectsFilterContextValue = ReturnType<typeof useDataStreamObjectFilters>;

export const tileEditorObjectsFilterDefaultValue: TileEditorObjectsFilterContextValue = {
    scope: {
        type: 'none',
        canFilter: true,
        isDynamic: false,
        filters: emptyFilters,
        isVariableScope: false,
        interactedNodeIds: []
    },
    selectedObjectIds: [],
    filters: emptyFilters,
    filterQueries: {} as unknown as TileEditorObjectsFilterContextValue['filterQueries'],
    filteredObjects: {
        objects: [],
        isFetchingObjects: false,
        isFetchingNextObjectsPage: false,
        isLoadingObjects: false,
        hasNextObjectsPage: false,
        count: 0,
        fetchNextObjectsPage: async () =>
            ({}) as InfiniteQueryObserverResult<{ objects: Node[]; count: number; nextPage: number }, unknown>,
        isPreviousData: false
    },
    isConfigured: false,
    selectedObjectsCount: 0,
    dynamicObjectsCount: 0,
    objectLimit: undefined,
    pluginLookup: new Map(),
    isLoadingFilters: false,
    isFiltered: false,
    sources: [],
    isDynamic: false,
    selectedObjects: [],
    filtersDisabled: false,
    scopesDisabled: false,
    filterScope: '',
    interactedObjects: [],
    sortOrder: undefined,
    setSortOrder: () => undefined,
    updateScope: () => undefined,
    resetFilters: () => undefined,
    canResetFilters: false
};

const TileEditorObjectsFilterContext = createContext<TileEditorObjectsFilterContextValue>(
    tileEditorObjectsFilterDefaultValue
);

TileEditorObjectsFilterContext.displayName = 'TileEditorObjectsFilterContext';

export const useTileEditorObjectsFilterContext = () => useContext(TileEditorObjectsFilterContext);

export default TileEditorObjectsFilterContext;
