/* eslint-disable no-console */

import axios, { AxiosError, AxiosResponse } from 'axios';

import { isOpenAccess } from 'lib/openAccessUtil';
import Config from '../config';
import ClipboardToast from '../ui/notifications/ClipboardToast';
import Auth from './Auth';

export const ErrorToastOptions = {
    /**
     * Set to true to suppress all toast errors until further notice.
     *
     * Designed to be used before reload of the app to stop toasts appearing
     * due to the browser cancelling any outstanding XHR requests.
     */
    SuppressAll: false
};

export const handleResponse = <T>(response: AxiosResponse<T>) => response.data;

export const handleError = (error: AxiosError, suppressToast = false) => {
    let errorMessage = '';
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        errorMessage = `Support Code: ${Config.Release}:${error.response.headers['apigw-requestid']}`;

        if (error.response.status === 401) {
            Auth.logout({ forceSignInAfterSignOut: true });
            suppressToast = true;
        }

        // Suppress toast if we're currently retrying after a rate limit error
        if ((error.response.data as any)?.retryAfter) {
            suppressToast = true;
        }
    } else if (error.code === AxiosError.ERR_CANCELED) {
        console.log('Request canceled', error.message);
        suppressToast = true;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        errorMessage = 'Could not contact server. Most likely a connectivity issue.';
    }

    // We now cancel requests from open access if they are bound for a restricted API route.
    // We don't want to show these error to the end user
    const isCancelledOpenAccessRequest = isOpenAccess() && axios.isCancel(error);

    if (!(ErrorToastOptions.SuppressAll || suppressToast || isCancelledOpenAccessRequest)) {
        ClipboardToast(errorMessage);
    }

    throw error;
};

export const handleErrorNoToast = (error: AxiosError) => handleError(error, true);
