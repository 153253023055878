import { TooltipButton } from '@/components/Button';
import { faLocationPin, faLocationPinSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGraph } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { usePinNode } from 'components/map/hooks/usePinNode';

interface PinButtonProps {
    node: GraphologyNodeAttributes;
    className?: string;
}

export const PinButton: React.FC<PinButtonProps> = ({ node, className }) => {
    const pin = usePinNode();
    const graph = useGraph();

    const pinned = graph.getNodeAttribute(node.id, 'pinned');

    return (
        <TooltipButton
            variant='tertiary'
            onClick={(e) => {
                e.stopPropagation();
                pin(node.id, !pinned);
            }}
            title={pinned ? 'Unpin' : 'Pin'}
            className={className}
        >
            <FontAwesomeIcon icon={pinned ? faLocationPin : faLocationPinSlash} fixedWidth />
        </TooltipButton>
    );
};
