import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Editor, { Monaco } from '@monaco-editor/react';
import Color from 'color';
import { useEffect, useState } from 'react';

export interface SQLEditorProps {
    content: string;
    readOnly: boolean;
    theme?: object;
    handleError: (hasError: boolean) => void;
    contentValidator: (content?: string) => string | boolean;
    onValidUpdatedContent: (content?: string) => void;
}

const ignoreErrors = ['is declared but its value is never read', "implicitly has an 'any' type"];

const SQLEditor: React.FC<Partial<SQLEditorProps>> = (props) => {
    const editorBackground = Color(getComputedStyle(document.body).getPropertyValue('--componentBackgroundSecondary')).hex();
    const { content, contentValidator, onValidUpdatedContent, theme, handleError, readOnly = false } = props;

    const [configError, setConfigError] = useState(false); // Errors in our config
    const [SQLError, setSQLError] = useState(''); // JavaScript language errors

    const handleEditorWillMount = (monaco: Monaco) => {
        monaco.editor.defineTheme('squpTheme', {
            base: document.body.dataset.theme === 'dark' ? 'vs-dark' : 'vs',
            inherit: true,
            rules: [{ background: editorBackground } as { background: string; token: string }],
            colors: {
                'editor.background': editorBackground,
                'scrollbar.shadow': '#ffffff00'
            },
            ...(theme || {})
        });
    };

    // Watch config/js errors and report up if there is a change
    useEffect(() => {
        handleError && handleError(Boolean(configError) || Boolean(SQLError));
    }, [configError, SQLError, handleError]);

    const handleContentChange = (changedContent: string | undefined) => {
        if (SQLError) {
            setConfigError(false);
        }

        try {
            const updatedContent = changedContent;

            // Allow the editor consumer chance to validate the content:
            const contentErrors = contentValidator && contentValidator(updatedContent);
            if (contentErrors) {
                // @ts-ignore
                setConfigError(contentErrors);
                return;
            }

            setConfigError(false);
            onValidUpdatedContent?.(updatedContent);
            // eslint-disable-next-line no-empty
        } catch {
            // Ignore
        }
    };

    const handleEditorValidation = (markers: { message: string; endLineNumber: number }[]) => {
        let error = '',
            ignore = false;
        if (markers.length > 0) {
            let index = 0;
            do {
                const msg = markers[index].message;
                if (msg) {
                    for (const err of ignoreErrors) {
                        if (msg.includes(err)) {
                            ignore = true;
                            break;
                        }
                    }
                    if (ignore) {
                        continue;
                    }
                }
            } while (++index < markers.length);

            if (index < markers.length && markers[index].message) {
                error = `${markers[index].message} (Line ${markers[index].endLineNumber})`;
            }
        }
        setSQLError(error);
    };

    return (
        <div 
            className='flex flex-col flex-grow w-full h-full'
            data-testid='SQLEditor'
        >
            <Editor
                language='mysql'
                theme='squpTheme'
                wrapperProps={{
                    className: 'bg-componentBackgroundSecondary border border-outlinePrimary flex-1'
                }}
                loading={<span>Loading editor...</span>}
                defaultValue={content}
                onChange={handleContentChange}
                onValidate={handleEditorValidation}
                beforeMount={handleEditorWillMount}
                options={{
                    scrollBeyondLastLine: false,
                    minimap: { enabled: false },
                    renderLineHighlight: 'none',
                    padding: { top: 10 },
                    automaticLayout: true,
                    readOnly: readOnly
                }}
            />
            {(configError || SQLError) && (
                <div className='flex pt-2 truncate flex-nowrap'>
                    <div>
                        <FontAwesomeIcon icon={faCircleExclamation} className='ml-2 mr-2 text-statusErrorPrimary' />
                    </div>
                    <div>
                        <span className='text-sm text-textSecondary'>{`Configuration error: ${
                            configError || SQLError
                        }`}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

SQLEditor.propTypes = {};

export default SQLEditor;
