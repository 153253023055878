import { cn } from '@/lib/cn';
import { stateStrings } from '@squaredup/monitoring';
import LoadingSpinner from 'components/LoadingSpinner';
import { useNetworkBackground, useSetNodeHealth } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { useNodeHealth } from 'components/map/hooks/useNodeHealth';
import { TruncatedText } from 'components/TruncatedText';
import { mapBackgroundColors, mapFillColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { type KPIValue } from 'dynamo-wrapper';
import { useStatusConfig } from 'pages/status/utils/useStatusConfig';
import { useWorkspaceKpis } from 'queries/hooks/useWorkspaceKpis';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Handle, NodeProps, Position } from 'reactflow';
import { WorkspaceAvatar } from './common/Avatar';
import { borderHealthStateStyles, strokeHealthStateStyles } from './common/health';

export const KPI_NODE = 'kpiNode';
export const KPI_NODE_SIZE = 70;

export const KPINode = memo(({ id, data }: NodeProps<GraphologyNodeAttributes>) => {
    const background = useNetworkBackground();
    const kpisWithoutState: KPIValue[] | undefined = data.initalKPIs ? JSON.parse(data.initalKPIs) : undefined;

    const { data: config } = useStatusConfig();
    const { kpiTypeIds, viewSettings } = config || {};

    const setHealth = useSetNodeHealth();

    const healthState = useNodeHealth([data.data!]);

    setHealth(id, healthState);

    const { data: KPIs = kpisWithoutState ?? [] } = useWorkspaceKpis(data.data?.sourceId?.[0], {
        enabled: Boolean(kpisWithoutState?.length),
        select: (d) => d.filter(({ type }) => !kpiTypeIds?.length || kpiTypeIds?.includes(type))
    });

    if (!data.data) {
        throw new Error('No data provided for WorkspaceNode');
    }

    const label = data.data.name;
    return (
        <div
            style={{
                width: KPI_NODE_SIZE,
                height: KPI_NODE_SIZE
            }}
            className='relative pointer-events-none'
        >
            <div
                className={cn('absolute top-0 left-0 flex items-start space-x-0.5', !KPIs.length && 'items-center')}
                style={{
                    width: KPIs.length ? KPI_NODE_SIZE + 160 : KPI_NODE_SIZE,
                    height: KPIs.length ? KPI_NODE_SIZE + KPIs.length * 30 + 50 : KPI_NODE_SIZE
                }}
            >
                <div
                    className={cn(
                        'relative w-full inline-flex rounded-full pointer-events-auto overflow-hidden items-center justify-center flex-grow-0 flex-shrink-0',
                        mapBackgroundColors[background]
                    )}
                    style={{
                        width: KPI_NODE_SIZE,
                        height: KPI_NODE_SIZE
                    }}
                >
                    <svg viewBox='0 0 100 100' className={cn('absolute inset-0', mapFillColors[background])}>
                        <circle
                            cx='50%'
                            cy='50%'
                            r='40'
                            vectorEffect='non-scaling-stroke'
                            strokeWidth={5}
                            className={cn(
                                'stroke-filterActive',
                                mapFillColors[background],
                                healthState &&
                                    healthState !== stateStrings.unknown &&
                                    strokeHealthStateStyles[healthState]
                            )}
                            {...(!data.pinned && { strokeDasharray: '2 2' })}
                        />
                    </svg>

                    <WorkspaceAvatar workspaceId={data.data.sourceId?.[0]} label={label} />

                    <Handle
                        type='target'
                        position={Position.Top}
                        className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                    />

                    <Handle
                        type='source'
                        position={Position.Bottom}
                        className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                    />
                </div>

                <div className='w-40 space-y-2'>
                    {label && (
                        <>
                            <div
                                className={cn(
                                    'text-primary rounded-sm text-base inline-block pointer-events-auto max-w-full font-bold px-1 py-0.5 leading-tight',
                                    mapBackgroundColors[background],
                                    Boolean(KPIs?.length) && 'max-w-full mt-[28px]'
                                )}
                            >
                                <TruncatedText title={label}>{label}</TruncatedText>
                            </div>
                        </>
                    )}

                    {Boolean(KPIs?.length) && viewSettings?.kpis && (
                        <div
                            className={cn(
                                'w-full transition-opacity ease-in-out border border-l-0 divide-y pointer-events-auto border-dividerPrimary divide-dividerPrimary',
                                mapBackgroundColors[background]
                            )}
                        >
                            {KPIs.map(({ tileId, name, formattedValue, status, dashboardId }) => (
                                <Link
                                    key={tileId}
                                    to={`/dashboard/${dashboardId}?tile=${tileId}`}
                                    className={cn(
                                        'block px-2 py-1 border-l-4',
                                        status && borderHealthStateStyles[status]
                                    )}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <TruncatedText
                                        title={name}
                                        className='text-[8px] leading-tight text-textSecondary'
                                    />
                                    <TruncatedText
                                        title={formattedValue}
                                        className='font-bold text-[8px]'
                                        disabled={formattedValue === undefined}
                                    >
                                        {formattedValue ?? <LoadingSpinner size={8} />}
                                    </TruncatedText>
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
