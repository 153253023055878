import { cn } from '@/lib/cn';
import NetworkSkeleton from 'components/map/NetworkSkeleton';
import stringify from 'fast-json-stable-stringify';
import { useStatusConfig } from 'pages/status/utils/useStatusConfig';
import { useRef } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { LayoutFlow } from './components/LayoutFlow';
import { NetworkMapStoreProvider } from './context/NetworkMapStoreContext';
import { useGlobalMapData } from './data/useGlobalMapData';
import { NetworkMapStoreState } from './NetworkMapStore';
import { LayoutTypes } from './types';

export interface MapProps {
    className?: string;
    expandedNodeIds?: string[];
    layoutType?: LayoutTypes;
    background?: NetworkMapStoreState['background'];
}

export const TenantMap: React.FC<MapProps> = ({ className, background = 'backgroundSecondary' }) => {
    const { data, isLoading } = useGlobalMapData();

    const { data: config } = useStatusConfig();

    const mapId = useRef(`map-${uuid()}`);

    if (isLoading) {
        return <NetworkSkeleton variant='hierarchical' />;
    }

    return (
        <div id={mapId.current} className={cn('h-full w-full relative', className)}>
            <div className='absolute inset-0 w-full'>
                <NetworkMapStoreProvider
                    key={stringify({
                        ...config,
                        viewSettings: {
                            downstream: config?.viewSettings.downstream
                        }
                    })}
                    initalData={data}
                    initalExpandedNodeIds={data?.nodes?.map(({ id }) => id) ?? []}
                    mapId={mapId.current}
                    layoutType={LayoutTypes.hierarchyVertical}
                    background={background}
                >
                    <ReactFlowProvider key='mapReactFlow'>
                        <LayoutFlow key='mapReactFlowLayout2' />
                    </ReactFlowProvider>
                </NetworkMapStoreProvider>
            </div>
        </div>
    );
};
