import { Portal } from '@/components/Portal';
import { ClientDataStreamRequest, isNoData } from '@squaredup/data-streams';
import TileContext from 'contexts/TileContext';
import DataStreamBaseTile from 'dashboard-engine/basetiles/DataStreamBaseTile';
import { FullScreenWrapper } from 'dashboard-engine/fullscreen/FullScreenWrapper';
import { useFullscreen } from 'dashboard-engine/fullscreen/hooks/useFullscreen';
import useDataStream from 'dashboard-engine/hooks/useDataStream';
import { useGenerateDataStreamTileConfig } from 'dashboard-engine/hooks/useGenerateDataStreamTileConfig';
import { useVisualizationState } from 'dashboard-engine/hooks/useVisualizationState';
import { SaveToDashboardModal } from 'pages/explore/SaveToDashboardModal';
import { useEffect, useState } from 'react';
import { TileHeaderActions } from 'ui/tile/tileHeader/TileHeaderActions';
import { DataStreamErrors } from './DataStreamErrors';
import { DataStreamRetrySkeleton } from './DataStreamRetrySkeleton';
import { GlobalDataStreamContext } from './GlobalDataStreamContext';
import { matchVisualisations } from './VisualisationMatching';

export type VisualizationDataState = 'hasData' | 'loading' | 'hasError' | 'noData';

// Extend ClientDataStreamRequest type to include a callback
type ClientDataStreamRequestWithData = ClientDataStreamRequest & {
    title?: string;
    config?: any;
    tileId?: string;
    showDetailedErrors?: boolean;
    sourceId?: string;
    dataStreamName?: string;
    onStateChange?: (state: VisualizationDataState) => void;
};

function DataStreamData(request: ClientDataStreamRequestWithData) {
    const { config, title, tileId, sourceId, showDetailedErrors = true, onStateChange } = request;

    const [isCopying, setIsCopying] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const { data, isLoading, error, retryAfter, completedAt } = useDataStream(request);

    const { visualizationState, onVisualizationStateChange } = useVisualizationState();
    const [bestVisualisation] = matchVisualisations(data, config);

    const hasData = !isNoData(data);

    const tileHeaderPortal = document.getElementById(`${tileId}Toolbar`);

    const { isFullscreenOpen, openFullscreen, closeFullscreen } = useFullscreen(true);

    // Might need to be useLayoutEffect if perf problems crop up in the future
    useEffect(() => {
        if (!onStateChange) {
            return;
        }

        if (isLoading) {
            onStateChange('loading');
        } else if (error) {
            onStateChange('hasError');
        } else {
            onStateChange(hasData ? 'hasData' : 'noData');
        }
    }, [hasData, isLoading, error, onStateChange]);

    const generatedTileConfig = useGenerateDataStreamTileConfig(
        request.dataStreamId ?? '',
        request.dataStreamName,
        title,
        bestVisualisation,
        request.scope,
        request.timeframe,
        request.options,
        config,
        sourceId
    );

    return !error ? (
        <GlobalDataStreamContext>
            <TileContext.Provider
                value={{
                    config: generatedTileConfig,
                    tileId,
                    showDetailedErrors,
                    preview: false,
                    tileReference: undefined,
                    isExporting,
                    setTileData: () => undefined,
                    setTileProcessedData: () => undefined,
                    setTileToolbar: () => undefined,
                    setIsExporting,
                    onEdit: () => undefined,
                    onClose: () => undefined,
                    onChange: () => undefined,
                    onCopy: () => setIsCopying(true)
                }}
            >
                <Portal container={tileHeaderPortal} className='order-10'>
                    <TileHeaderActions onOpenFullscreen={openFullscreen} />
                </Portal>

                <DataStreamBaseTile
                    config={generatedTileConfig}
                    hasDynamicVisualisation={!config?.visualisation?.type}
                    key='visualisation'
                    onVisualizationStateChange={onVisualizationStateChange}
                />

                {isCopying && <SaveToDashboardModal close={() => setIsCopying(false)} config={generatedTileConfig} />}

                {isFullscreenOpen && (
                    <FullScreenWrapper
                        isOpen={isFullscreenOpen}
                        visualizationState={visualizationState}
                        onClose={closeFullscreen}
                    />
                )}
            </TileContext.Provider>
        </GlobalDataStreamContext>
    ) : (
        <>
            {retryAfter && (
                <DataStreamRetrySkeleton
                    retryAfter={retryAfter}
                    completedAt={completedAt}
                    visType={config?.visualisation?.type}
                />
            )}
            {!retryAfter && <DataStreamErrors error={error} showDetailedErrors={showDetailedErrors} />}
        </>
    );
}

export default DataStreamData;
