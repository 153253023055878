import { DirectedGraph } from 'graphology';
import { createContext, useContext, useRef } from 'react';
import { useStore } from 'zustand';
import {
    createNetworkMapStore,
    NetworkMapStoreInstance,
    NetworkMapStoreProps,
    NetworkMapStoreState
} from '../NetworkMapStore';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes } from '../data/types';

type StoreProviderProps = React.PropsWithChildren<NetworkMapStoreProps>;

const NetworkMapStoreContext = createContext<NetworkMapStoreInstance | null>(null);

export function NetworkMapStoreProvider({ children, ...props }: StoreProviderProps) {
    const storeRef = useRef<NetworkMapStoreInstance>();

    if (!storeRef.current) {
        storeRef.current = createNetworkMapStore(props);
    }

    return <NetworkMapStoreContext.Provider value={storeRef.current}>{children}</NetworkMapStoreContext.Provider>;
}

export function useNetworkMapStoreContext<T>(selector: (state: NetworkMapStoreState) => T) {
    const store = useContext(NetworkMapStoreContext);

    if (!store) {
        throw new Error('Missing NetworkMapStoreContext.Provider in the tree');
    }

    return useStore(store, selector);
}

const graphSelector = (state: NetworkMapStoreState) => state.graph;
const mapIdSelector = (state: NetworkMapStoreState) => state.mapId;
const backgroundSelector = (state: NetworkMapStoreState) => state.background;
const layoutTypeSelector = (state: NetworkMapStoreState) => state.layoutType;
const getlayoutTypeSelector = (state: NetworkMapStoreState) => state.getLayoutType;
const setlayoutTypeSelector = (state: NetworkMapStoreState) => state.setLayoutType;
const setNodeHealthSelector = (state: NetworkMapStoreState) => state.setNodeHealth;
const getNodeHealthMap = (state: NetworkMapStoreState) => state.nodeHealthMap;

export const useGraph = (): DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes> =>
    useNetworkMapStoreContext(graphSelector);
export const useMapId = () => useNetworkMapStoreContext(mapIdSelector);
export const useNetworkBackground = () => useNetworkMapStoreContext(backgroundSelector);
export const useLayoutType = () => useNetworkMapStoreContext(layoutTypeSelector);
export const useGetLayoutType = () => useNetworkMapStoreContext(getlayoutTypeSelector);
export const useSetLayoutType = () => useNetworkMapStoreContext(setlayoutTypeSelector);
export const useSetNodeHealth = () => useNetworkMapStoreContext(setNodeHealthSelector);
export const useNodeHealthMap = () => useNetworkMapStoreContext(getNodeHealthMap);
