import { useCallback } from 'react';
import { useGraph } from '../context/NetworkMapStoreContext';
import { ungroupedNodes } from '../data/utils/rewriteLargeNodeGroups';

export function useUngroupNodes() {
    const graph = useGraph();

    return useCallback(
        (groupId: string, nodeIds: string[]) => {
            ungroupedNodes(graph, groupId, nodeIds);
        },
        [graph]
    );
}
