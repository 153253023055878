import { Portal } from '@/components/Portal';
import { cn } from '@/lib/cn';
import { arrow, autoUpdate, flip, offset, Placement, shift } from '@floating-ui/dom';
import { FloatingArrow, useFloating } from '@floating-ui/react';
import { Slot } from '@radix-ui/react-slot';
import { MarkdownBase } from 'components/markdown/MarkdownBase';
import { useTooltipContainerContext } from 'contexts/TooltipContainerContext';
import { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

const TOOLTIP_MARGIN = 12;
const TOOLTIP_ARROW_WIDTH = 10;
const TOOLTIP_ARROW_HEIGHT = 5;
const TOOLTIP_PLACEMENT = 'bottom';
const TOOLTIP_SHOW_DELAY = 400;
const TOOLTIP_HIDE_DELAY = 150;

export interface TooltipProps {
    title: string | ReactNode;
    placement?: Placement;
    disabled?: boolean | (() => boolean);
    delayShow?: number;
    delayHide?: number;
    offset?: number;
    className?: string;
    tooltipClassName?: string;
    style?: CSSProperties;
    asChild?: boolean;
    [property: string]: any;
}

const MarkdownWrapper = ({ content }: { content: string }) => (
    <div data-theme='dark'>
        <MarkdownBase content={content} />
    </div>
);

/**
 * @param title string/ReactNode
 * @param children ReactNode
 * @param placement 'bottom' | 'top' | 'left' | 'right'
 * @param disabled boolean
 * @param variant 'dark' | 'light' | 'success' | 'warning' | 'error' | 'info'
 * @param delayShow number
 * @param delayHide number
 * @param offset number
 * @param className css that wraps your tooltip, see StatusIndicator.tsx for example
 * @param tooltipClassName css class name that wraps your tooltip popover
 */
const Tooltip: React.FC<TooltipProps> = ({
    title,
    className,
    style,
    disabled,
    children,
    tooltipClassName,
    placement = TOOLTIP_PLACEMENT,
    asChild = false,
    ...props
}) => {
    const Comp = asChild ? Slot : 'div';
    const [isHovered, setIsHovered] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);

    const showTimer = useRef<number>();
    const hideTimer = useRef<number>();

    const handleOnMouseEnter = useCallback(() => {
        showTimer.current = window.setTimeout(() => setIsHovered(true), TOOLTIP_SHOW_DELAY);
        window.clearTimeout(hideTimer.current);
    }, []);

    const handleOnMouseLeave = useCallback(() => {
        hideTimer.current = window.setTimeout(() => setIsHovered(false), TOOLTIP_HIDE_DELAY);
        window.clearTimeout(showTimer.current);
    }, []);

    // Cleanup any timeouts when the component is unmounted
    useEffect(() => {
        return () => {
            window.clearTimeout(hideTimer.current);
            window.clearTimeout(showTimer.current);
        };
    }, []);

    const isDisabled = typeof disabled === 'function' ? disabled() : disabled;

    return (
        <>
            <Comp
                className={className}
                style={style}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                ref={elementRef}
                data-testid='tooltip'
                {...props}
            >
                {children}
            </Comp>

            {!isDisabled && isHovered && (
                <TooltipBody 
                    element={elementRef.current!}
                    title={title}
                    placement={placement}
                    tooltipClassName={tooltipClassName}
                    handleOnMouseEnter={handleOnMouseEnter}
                    handleOnMouseLeave={handleOnMouseLeave}
                />
            )}
        </>

    );
};

export interface TooltipBodyProps {
    element: HTMLDivElement;
    title: string | ReactNode;
    placement?: Placement;
    tooltipClassName?: string;
    handleOnMouseEnter?: () => void;
    handleOnMouseLeave?: () => void;
}

const TooltipBody: React.FC<TooltipBodyProps> = ({ 
    element, 
    title, 
    placement, 
    tooltipClassName,
    handleOnMouseEnter,
    handleOnMouseLeave
}) => {
    const arrowRef = useRef<SVGSVGElement>(null);
    const tooltipContainer = useTooltipContainerContext();

    const { x, y, strategy, refs, context } = useFloating({
        elements: { reference: element },
        placement,
        strategy: 'absolute',
        whileElementsMounted: autoUpdate,
        middleware: [
            offset({ mainAxis: TOOLTIP_MARGIN }), 
            arrow({ element: arrowRef.current! }),
            flip({ fallbackAxisSideDirection: 'start' }), 
            shift()
        ]
    });

    return (
        <Portal container={tooltipContainer ?? undefined}>
            <div 
                className={cn('fixed z-50 p-4 text-sm bg-tooltipBackground rounded text-textPrimary w-max max-w-xl', tooltipClassName)} 
                style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0
                }}
                ref={refs.setFloating}
                role='tooltip'
                onClick={(e) => e.stopPropagation()} // Prevent clicks on the tooltip leaking
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            >
                <TooltipContent content={title}/>
                <FloatingArrow 
                    ref={arrowRef} 
                    context={context} 
                    className='fill-tooltipBackground'
                    width={TOOLTIP_ARROW_WIDTH}
                    height={TOOLTIP_ARROW_HEIGHT}
                />
            </div>
        </Portal>
    );
};

interface TooltipContentProps {
    content: string | ReactNode;
}

const TooltipContent: React.FC<TooltipContentProps> = ({ content }) => {
    const isRichTooltip = typeof content !== 'string'; 

    return (
        <div    
            className='text-textPrimary'
            data-theme='dark'
        >
            {isRichTooltip ? (
                <>{content}</>
            ) : (
                <MarkdownWrapper content={content} />
            )}
        </div>
    );
};

export default Tooltip;

