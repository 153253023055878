import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Tabs as ReactTabs, Tab, TabList, TabPanel } from 'react-tabs';

export interface TabOption {
    key?: string;
    label: string | ReactNode;
    className?: string;
    panelClassName?: string;
    component: ReactNode;
}
export interface TabsProps {
    tabs: TabOption[];
    tabsID: string;
    selectedIndex?: number;
    additionalControl?: ReactNode;
    className?: string;
    tabListClassName?: string;
    tabListWrapperClassName?: string;
    onSelect?: (index: number) => void;
    [key: string]: any;
}

export const Tabs: React.FC<TabsProps> = ({
    tabs,
    tabsID,
    selectedIndex,
    additionalControl,
    className,
    tabListClassName,
    tabListWrapperClassName,
    onSelect,
    ...props
}) => {
    // Tabs are either controlled by this component or the parent (selectedIndex). There is no other way to track the
    // active tab. The primary reason we do this is for conditional rendering of the underline animation
    const [activeTab, setActiveTab] = useState(0);
    const activeRef = useRef<HTMLDivElement>(null);

    // If the tabs are controlled, we use the selectedIndex to define the selected tab, else use the active tab state.
    const selectedTab = selectedIndex ?? activeTab;

    useEffect(() => {
        // If the selected tab changes, make sure the active tab in the tab list is in view
        activeRef.current?.scrollIntoView?.();
    }, [selectedTab]);

    return (
        <ReactTabs
            selectedIndex={selectedIndex}
            onSelect={(index) => (onSelect ? onSelect(index) : setActiveTab(index))}
            className={cn('flex flex-col min-h-0 h-full', className)}
            data-testid={tabsID}
            {...props}
        >
            <div className={cn('relative flex', tabListWrapperClassName)}>
                <div className='absolute bottom-0 left-0 w-full h-px bg-dividerPrimary'></div>
                <TabList
                    className={cn(
                        'relative w-full flex flex-1 shrink-0 text-textSecondary pb-px overflow-x-scroll tile-scroll-overflow scrollbar-thumb-statusUnknownPrimary',
                        tabListClassName
                    )}
                >
                    {tabs.map(({ key, label, className: tabClassName, ...rest }, index) => {
                        const isActive = index === selectedTab;

                        // use label, key, or index to get a text-based ID for this tab
                        const id = typeof label === 'string' ? label : key ?? index;

                        return (
                            <Tab
                                key={key ?? index}
                                selectedClassName='text-textPrimary'
                                className={cn(
                                    'shrink-0 py-4 px-6 text-center select-none relative text-sm font-semibold cursor-pointer focus:ring-0 outline-none aria-[disabled=true]:opacity-50 aria-[disabled=true]:cursor-not-allowed',
                                    tabClassName,
                                    tabs.length === 1 && 'cursor-auto'
                                )}
                                data-testid={`${tabsID}-${id}`}
                                {...rest}
                            >
                                {/* Placeholder element that takes up the full size of the Tab, 
                                    so we can use it as a ref to scroll the parent to */}
                                <div
                                    ref={isActive ? activeRef : null}
                                    className='absolute top-0 left-0 w-full h-full pointer-events-none'
                                />
                                <Text.H4>{label}</Text.H4>

                                {isActive && tabs.length > 1 && (
                                    <div
                                        className='absolute -bottom-0 left-0 w-full bg-primaryButtonBackground h-0.5'
                                        data-selected-tab={label}
                                    />
                                )}
                            </Tab>
                        );
                    })}
                </TabList>
                {additionalControl}
            </div>

            <div className='flex flex-col flex-1 min-h-0'>
                {tabs.map(({ component, panelClassName }, index) => (
                    <TabPanel
                        key={index}
                        selectedClassName='!block'
                        className={cn('hidden h-full min-h-0', panelClassName)}
                    >
                        {component}
                    </TabPanel>
                ))}
            </div>
        </ReactTabs>
    );
};
